'use client'

import { ImageWithFallback } from '@/components/atoms/ImageWithFallback'
import { RcSuspense } from '@/components/atoms/RcSuspense'
import { LeftScrollBox } from '@/components/molecules/interactive/LeftScrollBox'
import { ChatMobile } from '@/components/pages/Chat/_ChatMobile'
import { useFeatures } from '@/components/providers/site/FeatureProvider'
import { useFirebase } from '@/components/providers/site/FirebaseProvider'
import { useIsLgUp, useIsMdUp } from '@/core/hooks/useMediaQueries'
import { useNavigation } from '@/core/hooks/useNavigation'
import { useNavPin } from '@/core/hooks/useNavPin'
import { useUpDownScrolled } from '@/core/hooks/useUpDownScrolled'
import { RootRoute } from '@/core/route-keys'
import { useOrganization } from '@/entity/organization/useOrganization'
import Box from '@mui/material/Box'
import Paper from '@mui/material/Paper'
import Stack from '@mui/material/Stack'
import { useState } from 'react'
import { NotificationIcon } from '../site/NotificationIcon'
import { NavigationAvatar } from './NavigationAvatar'
import { NavigationFooter } from './NavigationFooter'
import { NavigationLink } from './NavigationLink'
import { NavigationMain } from './NavigationMain'
import { TogglePinnedButton } from './TogglePinnedButton'

/**
 * Container for visual presentation of Desktop navigation sections.
 */
export const NavigationLayout = ({ skipAlerts }: { skipAlerts?: boolean }) => {
  const { featChat, cfgAvatarRedirect } = useFeatures()
  const { organization } = useOrganization()
  const { user } = useFirebase()
  const { getPath } = useNavigation()
  const isMdUp = useIsMdUp()
  const isLgUp = useIsLgUp()
  const pinned = useNavPin()
  const { ref: scrollRef, isTop, isBottom } = useUpDownScrolled()
  const [hover, setHover] = useState(false)
  const expanded = !isMdUp || pinned || hover
  // How much page space to take up
  const containerWidth = !isMdUp || pinned ? 284 : 92
  // How much space it actually fills
  const maxWidth = 284
  const width = expanded ? maxWidth : 92
  const padding = 8

  return (
    <Paper
      onMouseEnter={() => setHover(true)}
      onMouseLeave={() => setHover(false)}
      elevation={0}
      square
      sx={{
        width: containerWidth,
        minWidth: containerWidth,
        position: 'relative'
      }}
    >
      <Paper
        square
        elevation={0}
        sx={{
          height: '100dvh',
          width: width,
          position: 'fixed',
          zIndex: 2,
          transition: expanded ? 'width 0.2s ease-out' : undefined
        }}
      >
        <Stack
          direction='column'
          flex='0 0 auto'
          sx={{
            height: '100%'
          }}
        >
          <Stack
            direction='row'
            alignItems='center'
            justifyContent={expanded ? 'space-between' : 'center'}
            mt={padding}
            pl={expanded ? 6.5 : 0}
            pr={expanded ? 4 : 0}
          >
            {/* Org Logo */}
            <Box>
              <NavigationLink
                to={
                  cfgAvatarRedirect
                    ? (cfgAvatarRedirect as string)
                    : getPath({ root: RootRoute.Root })
                }
                underline='none'
                color='inherit'
              >
                <ImageWithFallback
                  src={organization?.image || ''}
                  alt={organization?.name || ''}
                  width={40}
                  height={40}
                  priority
                  style={{ borderRadius: '4px' }}
                />
              </NavigationLink>
            </Box>
            {isLgUp && expanded ? <TogglePinnedButton /> : null}
          </Stack>
          {/* Main Nav (Grow to fit middle space) */}
          <LeftScrollBox
            ref={scrollRef}
            flex='1 1 auto'
            sx={{
              minHeight: 180,
              pl: expanded ? padding - 6 : 2,
              pr: isMdUp ? 0 : 4,
              pt: 4,
              maskImage:
                isTop && isBottom
                  ? null
                  : isTop
                    ? 'linear-gradient(to bottom, black 70%, transparent 100%)'
                    : isBottom
                      ? 'linear-gradient(to bottom, transparent 0%, black 30%)'
                      : null
            }}
          >
            <Box
              sx={{
                width: width - 16,
                position: 'absolute',
                right: 0,
                transition: expanded ? 'width 0.2s ease-out' : undefined
              }}
            >
              <NavigationMain expanded={expanded} />
            </Box>
          </LeftScrollBox>
          {/* Footer */}
          <Box sx={{ width: '100%', overflow: 'hidden' }}>
            <Stack
              direction='column'
              justifyContent='flex-start'
              alignItems={'flex-start'}
              sx={{ width: '100%', px: 3 }}
            >
              {user && !user?.isAnonymous ? (
                <>
                  {skipAlerts ? null : (
                    <>
                      <RcSuspense skipLoader skipError>
                        <Box px={1} width='100%'>
                          <NotificationIcon
                            disableRipple
                            button
                            expanded={expanded}
                          />
                        </Box>
                      </RcSuspense>
                      {featChat ? (
                        <RcSuspense skipLoader skipError>
                          <Box px={1} width='100%'>
                            <ChatMobile
                              disableRipple
                              button
                              expanded={expanded}
                            />
                          </Box>
                        </RcSuspense>
                      ) : null}
                    </>
                  )}
                  <RcSuspense skipLoader skipError>
                    <NavigationAvatar avatarOnly={!expanded} />
                  </RcSuspense>
                </>
              ) : null}
            </Stack>
            <Box sx={{ width: maxWidth }} px={5} py={2}>
              <RcSuspense skipLoader>
                <NavigationFooter expanded={expanded} />
              </RcSuspense>
            </Box>
          </Box>
        </Stack>
      </Paper>
    </Paper>
  )
}
