import Card from '@mui/material/Card'
import Stack from '@mui/material/Stack'
import { BracketMatchResource } from '@rallycry/api-suite-typescript/dist/models/BracketMatchResource'
import { CompetitionBracketResource } from '@rallycry/api-suite-typescript/dist/models/CompetitionBracketResource'
import { CompetitionEventResource } from '@rallycry/api-suite-typescript/dist/models/CompetitionEventResource'
import { CompetitionParticipantResource } from '@rallycry/api-suite-typescript/dist/models/CompetitionParticipantResource'
import { CompetitionResource } from '@rallycry/api-suite-typescript/dist/models/CompetitionResource'
import { MatchState } from '@rallycry/api-suite-typescript/dist/models/MatchState'
import { PropsWithChildren, useRef } from 'react'
import { isUndefined } from 'lodash-es'
import {
  BracketKind,
  CompetitionBracketSettings
} from '@rallycry/api-suite-typescript/dist/models'
import { useBracketMatchView } from '../../useBracketMatchView'
import { MatchActionDisplay } from './MatchActionDisplay'
import { MatchDetailDisplay } from './MatchDetailDisplay'
import { expandById, expander, ExpansionType } from '@/core/expand'
import { RcAlertItem } from '@/components/organisms/site/RcAlertItem'
import { useModal } from '@/components/organisms/modal/ModalProvider'
import { MarkdownDisplay } from '@/components/molecules/input/MarkdownDisplay'
import { useOnScreen } from '@/core/hooks/useOnScreen'
import { useMatchmPublicMatch } from '@/entity/matchm/useMatchmPublicMatch'
import { RouteParamOverrideProvider } from '@/components/providers/site/RouteParamOverrideProvider'
import { useIsLgDown } from '@/core/hooks/useMediaQueries'
import { RcButton } from '@/components/molecules/interactive/RcButton'
import Box from '@mui/material/Box'
import { useNavigation } from '@/core/hooks/useNavigation'
import { CompetitionRoute, RootRoute } from '@/core/route-keys'
import { RcTrans } from '@/components/atoms/RcTrans'

export interface MatchCardProps extends PropsWithChildren {
  competition: CompetitionResource | undefined
  fullScreen?: boolean
  hideDescription?: boolean
  match: BracketMatchResource | undefined
  onClose?: () => any
  onReset?: () => any
  onRemove?: () => any
  onShowRoster?: () => any
  onFlagged?: () => any
  showActions?: boolean
  onShowRosterActive?: boolean
  score1?: number | string
  score2?: number | string
}

export const MatchCard = ({
  children,
  fullScreen,
  hideDescription,
  match,
  competition,
  score1,
  score2,
  onShowRoster,
  showActions,
  onShowRosterActive,
  onReset,
  onRemove,
  onClose,
  onFlagged
}: MatchCardProps) => {
  const ref = useRef(null)
  const scrolled = useOnScreen(ref)
  const { navTo } = useNavigation()

  const {
    team1Result,
    team2Result,
    isMatchParticipant,
    isMatchCoach,
    isMatchComplete
  } = useBracketMatchView({
    match,
    competition
  })

  const bracket = expander<CompetitionBracketResource>(
    match,
    ExpansionType.CompetitionBracket
  )
  const competitionEvent = expander<CompetitionEventResource>(
    match,
    ExpansionType.CompetitionEvent
  )
  const settings = expandById<CompetitionBracketSettings>(
    bracket?.settings?.id,
    match?._expanded,
    ExpansionType.CompetitionBracketSettings
  )

  const { matches } = useMatchmPublicMatch({
    paused: settings?.kind !== BracketKind.LADDER || !scrolled,
    request: { id: match?.externalId },
    suspense: false
  })
  const ladderMatch = matches?.[0]

  const team1 = team1Result.team
  const team2 = team2Result.team
  const canReportScores =
    team1Result.team &&
    team2Result.team &&
    match?.state !== MatchState.COMPLETE &&
    (isMatchParticipant || isMatchCoach || !!competition?._links?.moderate)
  const isParticipant = team1Result.isParticipant || team2Result.isParticipant

  const isLgDown = useIsLgDown()
  const { isInModalContext } = useModal()

  const smallSize = isInModalContext || isLgDown

  const showDescription =
    match?.description && !hideDescription && !fullScreen && !isInModalContext

  return (
    <RouteParamOverrideProvider context={{ matchId: match?.id?.toString() }}>
      <Card ref={ref} elevation={2}>
        {showDescription ? (
          <RcAlertItem
            issue={{
              variant: 'outlined',
              sx: {
                borderBottomWidth: 0,
                borderBottomLeftRadius: 0,
                borderBottomRightRadius: 0,
                minHeight: undefined,
                paddingTop: 0,
                paddingBottom: 0
              },
              severity: 'info',
              children: (
                <MarkdownDisplay
                  markdown={match.description}
                  variant='body2'
                  paragraph={false}
                  textAlign='center'
                />
              )
            }}
          />
        ) : null}
        <Stack direction='column'>
          <MatchDetailDisplay
            isMatchComplete={isMatchComplete}
            bracket={bracket}
            competition={competition}
            fullScreen={fullScreen}
            event={competitionEvent}
            match={match}
            ladderMatch={ladderMatch}
            team1={team1}
            team2={team2}
            community1={team1Result.orgCommunity}
            community2={team2Result.orgCommunity}
            score1={
              isUndefined(team1Result.score) && isUndefined(score1)
                ? '-'
                : team1Result.score || score1
            }
            score2={
              isUndefined(team2Result.score) && isUndefined(score2)
                ? '-'
                : team2Result.score || score2
            }
            smallSize={smallSize}
          />
          {!fullScreen && !showActions ? (
            <Stack direction='row-reverse' pb={2}>
              <RcButton
                size='small'
                fullWidth={false}
                onClick={() => {
                  navTo({
                    root: RootRoute.Competition,
                    rootId: competition?.id,
                    subRoute: CompetitionRoute.Match,
                    subId: match?.id
                  })
                }}
              >
                <RcTrans i18nKey='competition:match.view-match' />
              </RcButton>
            </Stack>
          ) : (
            <MatchActionDisplay
              canReportScores={canReportScores}
              competition={competition}
              isParticipant={isParticipant}
              match={match}
              onClose={onClose}
              onFlagged={onFlagged}
              onRemove={onRemove}
              onReset={onReset}
              onShowRoster={onShowRoster}
              onShowRosterActive={onShowRosterActive}
              smallSize={smallSize}
            />
          )}
          {children}
        </Stack>
      </Card>
    </RouteParamOverrideProvider>
  )
}
