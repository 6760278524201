import Stack from '@mui/material/Stack'
import { BracketMatchResource } from '@rallycry/api-suite-typescript'
import { orderBy } from 'lodash-es'
import { MatchCard } from '../match/card/MatchCard'
import { PageItem } from '@/components/organisms/page/PageItem'
import { PageItemContent } from '@/components/organisms/page/PageItemContent'
import { useParsedParam } from '@/core/hooks/useRouteParams'
import { useCompetition } from '@/entity/competition/useCompetition'
import { useBracketMatchesUber } from '@/entity/bracket-match/useBracketMatchesUber'

export const TeamMatches = () => {
  const parsedId = useParsedParam('teamId')
  const { competition } = useCompetition()
  const {
    matches,
    query: { mutate }
  } = useBracketMatchesUber({ request: { entry: [parsedId] } })
  const sorted = orderBy(matches, ['id'], ['asc'])

  const displayMatches = (matches: BracketMatchResource[]) =>
    matches.map(it => {
      return (
        <MatchCard
          key={it.id}
          match={it}
          competition={competition}
          onFlagged={mutate}
        />
      )
    })

  return (
    <PageItem contained={false} fillContent>
      <PageItemContent>
        <Stack direction='column' spacing={3}>
          {displayMatches(sorted)}
        </Stack>
      </PageItemContent>
    </PageItem>
  )
}
