import Grid from '@mui/material/Grid'
import { RcTrans } from '@/components/atoms/RcTrans'
import { RcTextInput } from '@/components/molecules/input/RcTextInput'
import { RcButton } from '@/components/molecules/interactive/RcButton'
import { ModalConfiguration } from '@/components/organisms/modal/ModalConfiguration'
import { useCompetition } from '@/entity/competition/useCompetition'
import { useCompetitionParticipant } from '@/entity/competition/useCompetitionParticipant'
import { useUserAccount } from '@/entity/user/useUserAccount'

export const FreeAgentSettings = ({ onClose }: { onClose: () => void }) => {
  const { account } = useUserAccount()
  const { competitionId, settings } = useCompetition()
  const { involvement, joinSolo, removeSolo } = useCompetitionParticipant()

  const handleClose = () => {
    onClose()
  }

  const handleRemove = async () => {
    await removeSolo()
    handleClose()
  }

  const freeAgent = involvement?.find(it => !it.entry)
  const [fa1, fa2] = settings?.participantInquiry?.split('/') || []

  return (
    <Grid container direction='column' spacing={8}>
      <Grid item>
        <RcTextInput
          property='description'
          source={{ description: freeAgent?.description }}
          update={async values => {
            await joinSolo({
              competitionId,
              userId: account?.id!,
              description: values.description || ''
            })
          }}
          label={fa1}
          description={fa2}
          multiline
        />
      </Grid>

      <ModalConfiguration
        direction='row'
        title={<RcTrans i18nKey='competition:team.free-agent-edit-title' />}
        subtitle={
          <RcTrans i18nKey='competition:team.free-agent-edit-description' />
        }
      >
        <RcButton
          onClick={handleRemove}
          variant='outlined'
          color='error'
          fullWidth
        >
          <RcTrans i18nKey={`competition:team.disband-action-free-agent`} />
        </RcButton>

        <RcButton onClick={handleClose} variant='contained' fullWidth>
          <RcTrans i18nKey='shared.looks-good' />
        </RcButton>
      </ModalConfiguration>
    </Grid>
  )
}
