import Box from '@mui/material/Box'
import Grid from '@mui/material/Grid'
import Typography from '@mui/material/Typography'
import { last } from 'lodash-es'
import { useSearchParams } from 'next/navigation'
import { useEffect } from 'react'
import { RootRoute } from '@/core/route-keys'
import { CardsSection } from '@/components/organisms/card/CardsSection'
import { InfiniteScrollV2 } from '@/components/atoms/InfiniteScrollV2'
import { useOrganization } from '@/entity/organization/useOrganization'
import { useSearchDocuments } from '@/entity/site/useSearchDocuments'
import { useCMSFile } from '@/core/hooks/useCMSFile'
import { useIsMainLayout } from '@/core/hooks/useLayoutQueries'
import { useNavigation } from '@/core/hooks/useNavigation'
import { DiscoverCard } from '@/components/organisms/card/DiscoverCard'
import { EllipsisBox } from '@/components/molecules/text/EllipsisBox'

export const DocumentSearch = ({
  engine,
  ids,
  limit,
  omit,
  actions,
  direction = 'row',
  rootRoute,
  slideCards,
  onTotalResults
}: {
  engine: string
  ids?: string[]
  limit?: number
  omit?: string
  actions?: (doc: any) => React.ReactNode
  direction?: 'column' | 'row' | undefined
  rootRoute: RootRoute
  slideCards?: boolean
  onTotalResults?: (total: number) => void
}) => {
  const { isOrgModerator } = useOrganization()
  const { navTo } = useNavigation()
  const searchParams = useSearchParams()
  const isMainLayout = useIsMainLayout()
  const q = searchParams?.get('q') || ''
  const tag = searchParams?.get('tag')
  const spacing = 2

  const { getImageStorageUrl } = useCMSFile()

  // the main search result data
  const { data, trackClick, isValidating, size, setSize } = useSearchDocuments({
    query: tag ? '' : q,
    tags: tag ? [tag] : undefined,
    engine,
    ids,
    limit,
    omit,
    showExcluded: isOrgModerator
  })

  useEffect(() => {
    const totalResults = Number(last(data)?.meta?.page?.total_results || 0)
    onTotalResults?.(totalResults)
  }, [data, limit, onTotalResults])

  const render = (that: any, requestId: any) => (
    <DiscoverCard
      title={that.title?.raw}
      content={
        that.description?.raw ? (
          <EllipsisBox lineClamp={2} mt={1} breakAll={false}>
            <Typography variant='body2' paragraph color='text.secondary'>
              {that.description?.raw}
            </Typography>
          </EllipsisBox>
        ) : null
      }
      primaryChips={[
        ...(that.tags?.raw || []),
        ...(that.exclude?.raw === 'true' ? ['PREVIEW MODE'] : [])
      ].map((it: string) => ({
        label: it,
        onClick: () => navTo({ root: rootRoute, query: `tag=${it}` }, true)
      }))}
      headerImage={getImageStorageUrl(that.preview_url?.raw)}
      navigation={{
        root: rootRoute,
        rootId: that.slug?.raw.replace(/:/g, ''),
        subRoute: btoa(that?.model?.raw).replace(/=/g, '') as any,
        subId: btoa(that?._meta.id).replace(/=/g, '') as any
      }}
      onClick={() => {
        trackClick(that?._meta.id, requestId)
      }}
      actions={actions?.(that)}
    />
  )

  // t('shared.file-count', {count: 0})
  return slideCards ? (
    <CardsSection
      align='flex-start'
      cardItems={data?.[0]?.results}
      render={item => render(item, data?.[0]?.meta?.request_id)}
    />
  ) : (
    <Box>
      <Grid
        container
        direction={direction}
        spacing={spacing}
        alignItems='stretch'
      >
        {data
          ?.flatMap(it => it?.results)
          ?.map((that: any, idx: number) => {
            if (!that) return null
            return (
              <Grid
                item
                key={idx}
                xs={12}
                sm={4}
                md={isMainLayout ? 4 : 3}
                sx={{
                  paddingTop:
                    idx === 0 && direction === 'column'
                      ? '0 !important'
                      : undefined
                }}
              >
                {render(that, that?.meta?.request_id)}
              </Grid>
            )
          })}
      </Grid>
      {limit ? null : (
        <InfiniteScrollV2
          hasMore={size < last(data)?.meta?.page?.total_pages!}
          isLoading={isValidating}
          onLoad={() => setSize(size + 1)}
        />
      )}
    </Box>
  )
}
