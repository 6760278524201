import Grid from '@mui/material/Grid'
import Stack from '@mui/material/Stack'
import Tooltip from '@mui/material/Tooltip'
import Typography from '@mui/material/Typography'
import { BracketState } from '@rallycry/api-suite-typescript/dist/models/BracketState'
import { BracketSubmissionDocument } from '@rallycry/api-suite-typescript/dist/models/BracketSubmissionDocument'
import { CompetitionEntryResource } from '@rallycry/api-suite-typescript/dist/models/CompetitionEntryResource'
import dynamic from 'next/dynamic'
import { LeaderboardScoreForm } from './LeaderboardScoreForm'
import { SubmissionsDisplayPoints } from './SubmissionsDisplayPoints'
import { NavigationLink as Link } from '@/components/organisms/navigation/NavigationLink'
import { RcTrans } from '@/components/atoms/RcTrans'
import {
  ActionMenu,
  ActionMenuOption
} from '@/components/molecules/interactive/ActionMenu'
import { SubmissionScoreDetails } from '@/components/pages/Administration/components/scores/SubmissionScoreDetails'
import { IMAGE_TYPES } from '@/components/pages/CMS/dynamic/Gallery'
import { useBracket } from '@/entity/bracket/useBracket'
import { useCompetition } from '@/entity/competition/useCompetition'
import { useCompetitionParticipant } from '@/entity/competition/useCompetitionParticipant'
import { useBracketScoreSettings } from '@/entity/leaderboard/useBracketScoreSettings'
import { useBracketSubmissionDocument } from '@/entity/leaderboard/useBracketSubmissionDocument'
import { ContactFlow } from '@/flows/Site/ContactFlow/_ContactFlow'
import { some } from 'lodash-es'

const RcDateTimeText = dynamic(
  async () =>
    (await import('@/components/molecules/text/RcDateTimeText')).RcDateTimeText,
  { ssr: false, loading: () => <></> }
)

export const SubmissionsDisplayTableRow = ({
  submission,
  team,
  isCutoff = true
}: {
  submission: BracketSubmissionDocument
  team?: CompetitionEntryResource
  isCutoff?: boolean
}) => {
  const { isCompetitionModerator } = useCompetition()
  const { involvement } = useCompetitionParticipant()
  const isParticipant = some(involvement, it => it.entry?.id === team?.id)

  const { bracket } = useBracket()
  const { settings, bonuses } = useBracketScoreSettings()
  const { remove } = useBracketSubmissionDocument({
    request:
      isParticipant || isCompetitionModerator
        ? { assignment: submission?.assignment?.id }
        : undefined
  })

  const scores = [
    {
      setting: settings.scoreA,
      bonuses: bonuses.scoreABonuses,
      input: submission.scoreInputA
    },
    {
      setting: settings.scoreB,
      bonuses: bonuses.scoreBBonuses,
      input: submission.scoreInputB
    },
    {
      setting: settings.scoreC,
      bonuses: bonuses.scoreCBonuses,
      input: submission.scoreInputC
    },
    {
      setting: settings.scoreD,
      bonuses: bonuses.scoreDBonuses,
      input: submission.scoreInputD
    },
    {
      setting: settings.scoreE,
      bonuses: bonuses.scoreEBonuses,
      input: submission.scoreInputE
    }
  ]

  let idx = 0
  const options: ActionMenuOption[] = []

  submission?.description?.startsWith('http') &&
    options.push({
      key: idx++,
      action: async () => window.open(submission?.description),
      modal: () => (
        <Stack
          direction='column'
          justifyContent='center'
          alignItems='center'
          sx={{ height: '100%' }}
        >
          {IMAGE_TYPES.some(it => submission?.description?.includes(it)) ? (
            <img
              src={submission?.description}
              alt={submission.dateCreated?.toString()}
              style={{ maxHeight: '90vh' }}
            />
          ) : (
            <Link href={submission?.description}>
              {submission?.description}
            </Link>
          )}
        </Stack>
      ),
      modalProps: { fullScreen: true, noFooter: true },
      display: <RcTrans i18nKey='shared.review' />
    })

  const isStarted = bracket?.state === BracketState.STARTED

  team &&
    !isParticipant &&
    options.push({
      key: idx++,
      modal: ({ handleClose }) => (
        <ContactFlow
          onComplete={handleClose}
          initial='report'
          reportedPlayer={{ id: team.id, name: team.alternateName }}
        />
      ),
      display: <RcTrans i18nKey='shared.report' />
    })

  if (isStarted && (isParticipant || isCompetitionModerator))
    options.push({
      key: idx++,
      modal: ({ handleClose }) => (
        <LeaderboardScoreForm
          onComplete={handleClose}
          assignmentId={submission.assignment?.id!}
          ordinal={submission.ordinal!}
          initial={submission}
        />
      ),

      display: <RcTrans i18nKey='shared.edit' />
    })

  if (isStarted && (isParticipant || isCompetitionModerator))
    options.push({
      key: idx++,
      confirmation: <RcTrans i18nKey='shared.confirmation-message' />,
      action: () => remove(submission?.id!),
      display: <RcTrans i18nKey='shared.delete' />
    })

  options.push({
    key: idx++,
    disabled: true,
    display: (
      <Typography variant='subtitle2'>
        <RcDateTimeText date={submission.dateCreated} />
      </Typography>
    )
  })

  return (
    <Grid
      container
      direction='row'
      spacing={1}
      alignItems='center'
      onClick={e => e.stopPropagation()}
      sx={{ cursor: 'text' }}
    >
      {scores
        .filter(it => !!it.setting)
        .map(it => (
          <Grid item xs key={it.setting?.id}>
            <Tooltip
              title={
                <>
                  <Typography>{it.setting?.name}</Typography>
                  <SubmissionScoreDetails
                    score={it.setting!}
                    bonuses={it.bonuses}
                  />
                </>
              }
            >
              <span>
                <SubmissionsDisplayPoints
                  resource={it.setting!}
                  bonuses={it.bonuses}
                  score={it.input}
                />
              </span>
            </Tooltip>
          </Grid>
        ))}

      <Grid item xs display='flex' justifyContent='flex-end'>
        <ActionMenu options={options}>
          <Stack direction='column'>
            <Typography
              variant='h4'
              color={isCutoff ? 'text.secondary' : 'text.primary'}
              align='right'
              noWrap
            >
              <RcTrans
                i18nKey='shared.points'
                tOptions={{ points: submission.points?.toLocaleString() }}
              />
            </Typography>
          </Stack>
        </ActionMenu>
      </Grid>
    </Grid>
  )
}
