import { RcErrorDisplay } from '@/components/atoms/RcErrorDisplay'
import { RcTrans } from '@/components/atoms/RcTrans'
import { MarkdownFormikField } from '@/components/molecules/input/MarkdownFormikField'
import { StyledCheckbox } from '@/components/molecules/input/StyledCheckbox'
import { RcButton } from '@/components/molecules/interactive/RcButton'
import { RcIconButton } from '@/components/molecules/interactive/RcIconButton'
import {
  LabeledField,
  LabeledFieldHeader
} from '@/components/organisms/form/LabeledField'
import { ModalConfiguration } from '@/components/organisms/modal/ModalConfiguration'
import { ModalTrigger } from '@/components/organisms/modal/ModalTrigger'
import { NavigationLink as Link } from '@/components/organisms/navigation/NavigationLink'
import { useTime } from '@/core/hooks/useTime'
import { useBrackets } from '@/entity/bracket/useBrackets'
import { useCompetitionEvents } from '@/entity/competition/useCompetitionEvents'
import Box from '@mui/material/Box'
import MenuItem from '@mui/material/MenuItem'
import Stack from '@mui/material/Stack'
import Typography from '@mui/material/Typography'
import {
  CompetitionBracketResource,
  WinCondition
} from '@rallycry/api-suite-typescript'
import { BracketMatchResource } from '@rallycry/api-suite-typescript/dist/models/BracketMatchResource'
import { BracketMatchUpdateCommand } from '@rallycry/api-suite-typescript/dist/models/BracketMatchUpdateCommand'
import { EventKind } from '@rallycry/api-suite-typescript/dist/models/EventKind'
import { Form, Formik, FormikHelpers } from 'formik'
import { Select as FormikSelect, TextField } from 'formik-mui'
import { orderBy, range, some } from 'lodash-es'
import * as Yup from 'yup'
import { CompetitionEventCreateEdit } from '../schedule/CompetitionEventCreateEdit'
import Select from '@mui/material/Select'

type UpdateCommand = BracketMatchUpdateCommand & {
  rounds?: number[]
  bracketIds?: number[]
}

export const getRoundDisplay = (round: number) => (
  <>
    {round === 0 ? (
      <RcTrans i18nKey='competition:bracket.3rd-place-match' />
    ) : round > 0 ? (
      <RcTrans i18nKey='competition:bracket.upper-round' />
    ) : (
      <RcTrans i18nKey='competition:bracket.lower-round' />
    )}
    &nbsp;
    {round === 0 ? '' : Math.abs(round)}
  </>
)

const getBracketDisplay = (bracket: CompetitionBracketResource) => {
  return bracket.disambiguatingDescription
    ? `${bracket.disambiguatingDescription} - ${bracket.name}`
    : bracket.name
}

export const MatchEditForm = ({
  match,
  bulkEdit,
  bracketIds,
  roundIds,
  onSubmit
}: {
  match?: BracketMatchResource
  bulkEdit?: boolean
  bracketIds?: number[]
  roundIds?: number[]
  onSubmit: (
    values: UpdateCommand,
    helpers: FormikHelpers<UpdateCommand>
  ) => Promise<any>
}) => {
  const { displayDateTime } = useTime()
  const { events } = useCompetitionEvents()

  const { brackets } = useBrackets()

  const validation = Yup.object<UpdateCommand>({
    description: Yup.string(),
    winCondition: Yup.mixed().oneOf([
      WinCondition.BESTOF,
      WinCondition.FIRSTTO,
      'UNSET'
    ]),
    winConditionAmount: Yup.number().required(),
    event: Yup.number(),
    rounds: bulkEdit
      ? Yup.array().of(Yup.number()).min(1).required()
      : Yup.array().of(Yup.number()),
    bracketIds: bulkEdit
      ? Yup.array().of(Yup.number()).min(1).required()
      : Yup.array().of(Yup.number())
  })

  const filter = [EventKind.MATCH]
  const schedule = events(filter)?.all
  const rounds = range(1, 17)
    .concat(0)
    .concat(range(-1, -17, -1))

  return (
    <Formik
      enableReinitialize
      initialValues={
        {
          description: match?.description || '',
          winCondition: match?.winCondition || 'UNSET',
          winConditionAmount: match?.winConditionAmount || 2,
          event: match?.event?.id || 0,
          rounds: roundIds || [1],
          bracketIds: bracketIds || []
        } as UpdateCommand
      }
      validationSchema={validation}
      onSubmit={onSubmit}
    >
      {({ isSubmitting, values, setFieldValue }) => (
        <Form id='matches-edit-form'>
          <Stack display='flex' direction='column' spacing={2}>
            {bulkEdit ? (
              <>
                <LabeledFieldHeader
                  label={<RcTrans i18nKey='competition:bracket.brackets' />}
                />
                <Select
                  value={values.bracketIds}
                  onChange={e => {
                    setFieldValue('bracketIds', e.target.value || [])
                  }}
                  variant='outlined'
                  fullWidth
                  displayEmpty
                  multiple
                  endAdornment={
                    some(values.bracketIds) ? (
                      <RcIconButton
                        size='extraSmall'
                        icon={['fal', 'times']}
                        onClick={() => setFieldValue('bracketIds', [])}
                        sx={{ mr: 7 }}
                      />
                    ) : null
                  }
                  renderValue={(selected: any) =>
                    (selected as number[]).length === 0
                      ? ''
                      : (selected as number[]).length === 1
                        ? brackets.find(it => it.id === selected[0])?.name
                        : `${(selected as number[]).length} selected`
                  }
                >
                  {orderBy(brackets, getBracketDisplay).map(bracket => (
                    <MenuItem key={bracket.id} value={bracket.id}>
                      <StyledCheckbox
                        checked={values.bracketIds?.includes(bracket.id!)}
                      />
                      {getBracketDisplay(bracket)}
                    </MenuItem>
                  ))}
                </Select>
                <RcErrorDisplay name='bracketIds' />
              </>
            ) : null}
            {bulkEdit ? (
              <>
                <LabeledFieldHeader
                  label={<RcTrans i18nKey='competition:bracket.rounds' />}
                />
                <Select
                  value={values.rounds}
                  onChange={e => {
                    setFieldValue('rounds', e.target.value || [])
                  }}
                  variant='outlined'
                  fullWidth
                  displayEmpty
                  multiple
                  endAdornment={
                    some(values.rounds) ? (
                      <RcIconButton
                        size='extraSmall'
                        icon={['fal', 'times']}
                        onClick={() => setFieldValue('rounds', [])}
                        sx={{ mr: 7 }}
                      />
                    ) : null
                  }
                  renderValue={(selected: any) =>
                    (selected as number[]).length === 0
                      ? ''
                      : (selected as number[]).length === 1
                        ? getRoundDisplay(selected[0])
                        : `${(selected as number[]).length} selected`
                  }
                >
                  {rounds.map(key => (
                    <MenuItem key={key} value={Number(key)}>
                      <StyledCheckbox checked={values.rounds?.includes(key)} />
                      {getRoundDisplay(Number(key))}
                    </MenuItem>
                  ))}
                </Select>
                <RcErrorDisplay name='rounds' />
              </>
            ) : null}

            <Box>
              <LabeledFieldHeader
                label={<RcTrans i18nKey='shared.description' />}
              />
              <MarkdownFormikField name='description' />
            </Box>

            <LabeledField
              component={FormikSelect}
              name='winCondition'
              variant='outlined'
              label={
                <RcTrans i18nKey={'competition:bracket.win-condition-label'} />
              }
              info={
                <RcTrans
                  i18nKey={'competition:bracket.win-condition-tooltip'}
                />
              }
              fullWidth
            >
              <MenuItem value={'UNSET'}>
                <RcTrans i18nKey='competition:win-condition.UNSET' />
              </MenuItem>
              <MenuItem value={WinCondition.BESTOF}>
                <RcTrans i18nKey={'competition:win-condition.BESTOF'} />
              </MenuItem>
              <MenuItem value={WinCondition.FIRSTTO}>
                <RcTrans i18nKey={'competition:win-condition.FIRSTTO'} />
              </MenuItem>
            </LabeledField>

            <LabeledField
              component={TextField}
              name='winConditionAmount'
              type='number'
              variant='outlined'
              disabled={(values.winCondition as any) === 'UNSET'}
              label={
                values?.winCondition === WinCondition.BESTOF ? (
                  <RcTrans i18nKey='competition:bracket.best-of-label' />
                ) : (
                  <RcTrans i18nKey='competition:bracket.first-to-label' />
                )
              }
              fullWidth
            />

            <Box>
              <LabeledField
                component={FormikSelect}
                name='event'
                variant='outlined'
                label={<RcTrans i18nKey='shared.event' />}
                fullWidth
              >
                <MenuItem value={-1}>
                  {bulkEdit ? (
                    <RcTrans i18nKey='competition:match.remove-event' />
                  ) : (
                    <RcTrans i18nKey='shared.none' />
                  )}
                </MenuItem>
                {bulkEdit ? (
                  <MenuItem value={0}>
                    <RcTrans i18nKey='competition:match.keep-event' />
                  </MenuItem>
                ) : null}
                {schedule?.map(it => (
                  <MenuItem key={it.id} value={it.id}>
                    <Stack direction='column' spacing={1}>
                      <Typography>{it.name}</Typography>
                      <Typography variant='subtitle1'>
                        {displayDateTime(it.startDate)} -{' '}
                        {displayDateTime(it.endDate)}
                      </Typography>
                    </Stack>
                  </MenuItem>
                ))}
              </LabeledField>
            </Box>

            <ModalTrigger
              activation={handleOpen => (
                <Typography variant='subtitle2'>
                  <Link onClick={handleOpen}>
                    <RcTrans i18nKey='competition:match.create-match-event' />
                  </Link>
                </Typography>
              )}
            >
              {({ handleClose }) => (
                <CompetitionEventCreateEdit
                  resource={{ kind: EventKind.MATCH }}
                  onComplete={function () {
                    handleClose()
                  }}
                />
              )}
            </ModalTrigger>
          </Stack>
          <ModalConfiguration
            title={<RcTrans i18nKey='competition:match.edit-match' />}
            subtitle={
              bulkEdit ? (
                <RcTrans i18nKey='competition:match.edit-match-subtitle' />
              ) : null
            }
          >
            <RcButton
              variant='contained'
              fullWidth
              type='submit'
              disabled={isSubmitting}
              form='matches-edit-form'
            >
              <RcTrans i18nKey='shared.update' />
            </RcButton>
          </ModalConfiguration>
        </Form>
      )}
    </Formik>
  )
}
