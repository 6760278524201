import Alert from '@mui/material/Alert'
import Stack from '@mui/material/Stack'
import Typography from '@mui/material/Typography'
import { useState } from 'react'
import { Conduct } from './Conduct'
import { Rule } from './Rule'
import { NavigationLink as Link } from '@/components/organisms/navigation/NavigationLink'
import { RcTrans } from '@/components/atoms/RcTrans'
import { ModalCard } from '@/components/organisms/modal/ModalCard'
import { ModalConfiguration } from '@/components/organisms/modal/ModalConfiguration'
import {
  ModalProvider,
  useModal
} from '@/components/organisms/modal/ModalProvider'
import { PageItemContent } from '@/components/organisms/page/PageItemContent'

export const Rules = () => {
  const { isInModalContext } = useModal()
  const [modalOpen, setModalOpen] = useState(false)

  const handleClose = () => {
    setModalOpen(false)
  }

  const alert = (
    <Alert severity='warning' sx={{ m: 3 }} icon={null}>
      <Typography variant='body0'>
        <RcTrans i18nKey='competition:rules.disclaimer'>
          <Link sx={{ cursor: 'pointer' }} onClick={() => setModalOpen(true)}>
            link
          </Link>
        </RcTrans>
      </Typography>
    </Alert>
  )

  const content = (
    <Stack direction='column' spacing={5}>
      <Rule
        rule={'critical'}
        title={<RcTrans i18nKey='competition:rules.critical-header' />}
        hint={<RcTrans i18nKey='competition:rules.critical-hint' />}
      />
      <Rule
        rule={'registration'}
        title={<RcTrans i18nKey='competition:rules.registration-header' />}
        hint={<RcTrans i18nKey='competition:rules.registration-hint' />}
      />
      <Rule
        rule={'format'}
        title={<RcTrans i18nKey='competition:rules.format-header' />}
        hint={<RcTrans i18nKey='competition:rules.format-hint' />}
      />
      <Rule
        rule={'match'}
        title={<RcTrans i18nKey='competition:rules.match-header' />}
        hint={<RcTrans i18nKey='competition:rules.match-hint' />}
      />
      <Rule
        rule={'prizing'}
        title={<RcTrans i18nKey='competition:rules.prizing-header' />}
        hint={<RcTrans i18nKey='competition:rules.prizing-hint' />}
      />
      <Rule
        rule={'general'}
        title={<RcTrans i18nKey='competition:rules.general-header' />}
        hint={<RcTrans i18nKey='competition:rules.additional-info-hint' />}
      />
      <ModalProvider>
        <ModalCard
          open={modalOpen}
          onClose={handleClose}
          title={<RcTrans i18nKey='competition:conduct.title' />}
          subtitle={<RcTrans i18nKey='competition:conduct.subtitle' />}
        >
          <Conduct />
        </ModalCard>
      </ModalProvider>
    </Stack>
  )

  return (
    <>
      {isInModalContext ? <ModalConfiguration topContent={alert} /> : alert}
      {isInModalContext ? (
        content
      ) : (
        <PageItemContent>{content}</PageItemContent>
      )}
    </>
  )
}
