import Stack from '@mui/material/Stack'
import { FormQuestionKind } from '@rallycry/api-suite-typescript/dist/models/FormQuestionKind'
import { FormQuestionResource } from '@rallycry/api-suite-typescript/dist/models/FormQuestionResource'
import { capitalize, first, isUndefined } from 'lodash-es'
import moment from 'moment-timezone'
import Box from '@mui/material/Box'
import { ImageOrFileDisplay } from '@/components/molecules/input/RcFileInput'
import { RcIconButton } from '@/components/molecules/interactive/RcIconButton'
import { LabeledContent } from '@/components/molecules/text/LabeledContent'
import { RcTrans } from '@/components/atoms/RcTrans'
import { RcIcon } from '@/components/atoms/RcIcon'
import { useFormResponse } from '@/entity/curated-forms/useFormResponse'
import { useTime } from '@/core/hooks/useTime'
import { calcElevationString } from '@/style/palette'
import { ImageDisplay } from '@/components/atoms/ImageDisplay'

export const FormResponseQuestionDisplay = ({
  responseId,
  question,
  canUnlock
}: {
  responseId?: number
  question: FormQuestionResource
  canUnlock?: boolean
}) => {
  const { displayDateUTC, displayDateTime } = useTime()
  const { response, updateFormAnswer, createFormAnswer } = useFormResponse({
    idOrKey: responseId
  })

  const answers = response?._expanded?.formAnswer?.filter(
    it => it.question?.id === question?.id
  )
  const answer = first(answers)
  const selected = answers
    ?.map(it => ({
      ...it,
      ...response?._expanded?.formOption?.find(
        that => it.option?.id === that.id || Number(it.value) === that.id
      )
    }))
    ?.map(it => (it?.other ? it.value : it?.name))
    .join(', ')

  return (
    <Stack direction='row'>
      <LabeledContent
        label={
          <Stack
            direction='row'
            justifyContent='space-between'
            alignItems='center'
          >
            {question?.name}
            {canUnlock ? (
              <RcIconButton
                icon={['fal', 'lock']}
                size='small'
                IconProps={{ size: 'xs' }}
                disableRipple
                onClick={() =>
                  answer?.id
                    ? updateFormAnswer(answer?.id!, { locked: false })
                    : createFormAnswer({
                        complete: false,
                        question: question?.id!,
                        value: '',
                        locked: false
                      })
                }
              />
            ) : (
              <RcIcon icon={['fal', 'unlock']} size='xs' />
            )}
          </Stack>
        }
      >
        {{
          [FormQuestionKind.APPROVAL]: () => (
            <>
              {isUndefined(answer?.value) ? (
                <RcTrans i18nKey='shared.no-response' />
              ) : (
                capitalize(answer?.value)
              )}
            </>
          ),
          [FormQuestionKind.ASSIGNMENT]: () => answer?.value || 'n/a',
          [FormQuestionKind.CHECKBOXES]: () =>
            selected || <RcTrans i18nKey='shared.no-response' />,
          [FormQuestionKind.DATE]: () =>
            displayDateUTC(moment(answer?.value)) || (
              <RcTrans i18nKey='shared.no-response' />
            ),
          [FormQuestionKind.DATETIME]: () =>
            displayDateTime(moment(answer?.value)) || (
              <RcTrans i18nKey='shared.no-response' />
            ),
          [FormQuestionKind.FILEUPLOAD]: () =>
            answer?.value ? (
              <ImageOrFileDisplay url={answer?.value} />
            ) : (
              <RcTrans i18nKey='shared.no-response' />
            ),
          [FormQuestionKind.LONGTEXT]: () =>
            answer?.value || <RcTrans i18nKey='shared.no-response' />,
          [FormQuestionKind.RADIO]: () =>
            selected || <RcTrans i18nKey='shared.no-response' />,
          [FormQuestionKind.SCALE]: () =>
            answer?.value || <RcTrans i18nKey='shared.no-response' />,
          [FormQuestionKind.SELECT]: () =>
            selected ||
            answer?.value || <RcTrans i18nKey='shared.no-response' />,
          [FormQuestionKind.SHORTTEXT]: () =>
            answer?.value || <RcTrans i18nKey='shared.no-response' />,
          [FormQuestionKind.SIGNATURE]: () =>
            answer?.value ? (
              <Box
                sx={theme => ({
                  backgroundColor: theme.palette.primary.main,
                  backgroundImage: calcElevationString(theme.palette.mode, [
                    20
                  ]),
                  borderRadius: 1,
                  overflow: 'hidden'
                })}
              >
                <ImageDisplay
                  path={answer?.value}
                  alt={'saved signature'}
                  width={444}
                  height={150}
                />
              </Box>
            ) : (
              <RcTrans i18nKey='shared.no-response' />
            )
        }[question.kind!]?.()}
      </LabeledContent>
    </Stack>
  )
}
