import Box from '@mui/material/Box'
import Chip from '@mui/material/Chip'
import Typography, { TypographyProps } from '@mui/material/Typography'
import { first, last } from 'lodash-es'
import ReactMarkdown from 'react-markdown'
import { NavigationLink as Link } from '@/components/organisms/navigation/NavigationLink'
import { ContactFlowLink } from '@/flows/Site/ContactFlow/ContactFlowLink'
import { RenderReaction } from '@/components/pages/Chat/ChatMessageText'
import { FormResponseAdhoc } from '@/components/pages/CuratedForms/Response/FormResponseAdhoc'
import { useOrganization } from '@/entity/organization/useOrganization'

const urlRegex = new RegExp(/(?:^|\s)((https?:\/\/))[^\s<]+(?!\))/gi)
const imgRegex = new RegExp(/\.(jpeg|jpg|gif|png)$/i)

export interface MarkdownDisplayProps {
  color?: 'primary' | 'secondary'
  markdown?: string
  paragraph?: boolean
  textAlign?: 'left' | 'center' | 'right'
  textOnly?: boolean
  typographyProps?: TypographyProps
  variant?: 'body0' | 'body1' | 'body2' | 'subtitle1' | 'subtitle2'
}

/* Styles and renders markdown with substitutions for things like @handle, :emoji:, etc */
export const MarkdownDisplay = ({
  color,
  markdown,
  variant = 'body1',
  textAlign,
  paragraph = true,
  textOnly,
  typographyProps = {}
}: MarkdownDisplayProps) => {
  const { profile } = useOrganization()
  const addLinks = (markdown: string) =>
    (markdown || '')
      .replace(urlRegex, str =>
        textOnly
          ? `${str}`
          : imgRegex.test(str)
            ? `[img](${str})`
            : `[${str}](${str})`
      )
      .replace('{{discordLink}}', `${profile?.primaryDiscordInviteUrl}`)
      .replace(/&#8192/g, '&nbsp;')

  if (!markdown) return null

  return (
    <Box
      sx={theme => ({
        color:
          color === 'primary'
            ? theme.palette.text.primary
            : theme.palette.text.secondary,
        '& img': {
          maxWidth: '100%'
        },
        '& code': {
          whiteSpace: 'pre-line'
        },
        '& li': {
          fontSize: '1.0rem'
        }
      })}
    >
      <ReactMarkdown
        components={{
          a: props => {
            const propChild = first(props.children) as string
            const href =
              typeof props?.node?.properties?.href === 'string'
                ? props?.node?.properties?.href
                : props.href
            return propChild?.startsWith?.('@') &&
              !props.href?.startsWith('http') ? (
              <Box display='inline-flex' mr={1}>
                {textOnly ? (
                  propChild
                ) : (
                  <Chip label={propChild} color='primary' />
                )}
              </Box>
            ) : propChild?.startsWith?.(':') ? (
              <Box display='inline-flex' mr={1}>
                <RenderReaction id={propChild} />
              </Box>
            ) : propChild?.startsWith?.('img') ? (
              <img
                alt={props.href}
                src={props.href}
                style={{
                  borderRadius: 10,
                  objectFit: 'contain',
                  height: '100%',
                  maxHeight: 'calc(40vh)'
                }}
              />
            ) : props.href?.includes('form-') ? (
              <FormResponseAdhoc
                formId={Number(last(props.href?.split('-'))?.replace('}}', ''))}
                activation={handleOpen => (
                  <Link onClick={handleOpen} tabIndex={0}>
                    {propChild}
                  </Link>
                )}
              />
            ) : props.href?.includes('contactLink') ? (
              <ContactFlowLink>{propChild}</ContactFlowLink>
            ) : href?.includes('discord') ? (
              <Link {...props} href={href} target='' />
            ) : href?.includes('youtube') ? (
              <iframe
                allow={
                  'accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share'
                }
                allowFullScreen
                height='100%'
                src={href.replace('watch?v=', 'embed/')}
                title={href.replace('watch?v=', 'embed/')}
                style={{ border: 0, minHeight: 500 }}
                width='100%'
              />
            ) : (
              <Link {...props} />
            )
          },
          h1: props => (
            <Typography {...props} {...typographyProps} variant='h1' />
          ),
          h2: props => (
            <Typography {...props} {...typographyProps} variant='h2' />
          ),
          h3: props => (
            <Typography {...props} {...typographyProps} variant='h3' />
          ),
          h4: props => (
            <Typography {...props} {...typographyProps} variant='h4' />
          ),
          h5: props => (
            <Typography {...props} {...typographyProps} variant='body0' />
          ),
          h6: props => (
            <Typography {...props} {...typographyProps} variant='body0' />
          ),
          p: props => (
            <Typography
              align={textAlign ? textAlign : 'left'}
              {...props}
              {...typographyProps}
              variant={variant}
              paragraph={paragraph}
            />
          )
        }}
        key={markdown}
      >
        {addLinks(markdown)}
      </ReactMarkdown>
    </Box>
  )
}
