import Typography from '@mui/material/Typography'
import { JoinRestriction } from '@rallycry/api-suite-typescript/dist/models/JoinRestriction'
import { useMachine } from '@xstate/react'
import { first, orderBy, uniqBy } from 'lodash-es'
import { useEffect } from 'react'
import { SignUpMode } from '@rallycry/api-suite-typescript/dist/models'
import { ContactFlowLink } from '../../Site/ContactFlow/ContactFlowLink'
import { FindCommunity } from './FindCommunity'
import { SetCommunity } from './SetCommunity'
import { SetHandle } from './SetHandle'
import { SetMembers } from './SetMembers'
import { SetTeam } from './SetTeam'
import { JoinFlowContext, joinFlowMachine } from './join-flow-machine'
import { useImpersonation } from '@/components/providers/site/ImpersonationProvider'
import { useFirebase } from '@/components/providers/site/FirebaseProvider'
import { useFeatures } from '@/components/providers/site/FeatureProvider'
import { Rules } from '@/components/pages/Competition/components/info/Rules/_Rules'
import { ModalConfiguration } from '@/components/organisms/modal/ModalConfiguration'
import { RcButton } from '@/components/molecules/interactive/RcButton'
import { invokeService } from '@/core/utils'
import { RcTrans } from '@/components/atoms/RcTrans'
import { RcSuspense } from '@/components/atoms/RcSuspense'
import { RcLoader } from '@/components/atoms/RcLoader'
import { useUserSelfOrgCommunities } from '@/entity/user/useUserSelfOrgCommunities'
import { useUserAccount } from '@/entity/user/useUserAccount'
import { useCompetitionParticipant } from '@/entity/competition/useCompetitionParticipant'
import { useCompetition } from '@/entity/competition/useCompetition'
import { useCompetitionTeamSelfApplications } from '@/entity/competition-team/useCompetitionTeamSelfApplications'

interface CompetitionJoinFlowProps {
  code?: string
  selectedTeam?: number
  freeAgent?: boolean
  skipRules?: boolean
  onComplete: () => void
  onCancel?: () => void
}

export const JoinFlow = ({
  code,
  selectedTeam,
  freeAgent,
  skipRules,
  onComplete,
  onCancel
}: CompetitionJoinFlowProps) => {
  const { user } = useUserAccount()
  const { competitionId, isSoloCompetition, isCommunityOptional, settings } =
    useCompetition()
  const { apply, applyWithCode } = useCompetitionTeamSelfApplications()
  const { createTeam, joinSolo, isPlayer } = useCompetitionParticipant()

  const { devXstate } = useFeatures()
  const { logEvent } = useFirebase()
  const { isProfile } = useImpersonation()
  const { orgCommunities: selfCommunities } = useUserSelfOrgCommunities()
  const { orgCommunities: parentOrgCommunities } = useUserSelfOrgCommunities({
    skipImpersonation: true,
    paused: !isProfile
  })

  const orgCommunities = orderBy(
    uniqBy([...selfCommunities, ...parentOrgCommunities], it => it.id),
    [it => it.name?.toLocaleLowerCase()]
  )

  const [state, send] = useMachine(joinFlowMachine, {
    devTools: devXstate,
    context: {
      user,
      selectedTeam: selectedTeam,
      orgCommunities,
      isFreeAgent: freeAgent,
      skipRules,
      isSoloCompetition,
      teamRepresentingOptional: isCommunityOptional,
      nonParticipantLeader:
        isPlayer() || settings?.signUpMode === SignUpMode.NONPLAYERLEADERONLY,
      nonParticipantLeaderLocked:
        isPlayer() || settings?.signUpMode === SignUpMode.NONPLAYERLEADERONLY
    },
    services: {
      // eslint-disable-next-line  @typescript-eslint/no-unused-vars
      completed: async _ => {
        onComplete()
      },
      cancel: async () => {
        onCancel?.()
      },
      join: async (context: JoinFlowContext) =>
        invokeService(() =>
          // When user is a free agent or has a selected team do the appropriate join call, otherwise assume
          // team is being created. Usage inside the machine does not care which is being done.
          context.isFreeAgent
            ? joinSolo({
                competitionId,
                userId: user?.id!,
                description: context.alternateName,
                representingOnCreate: orgCommunities.map(it => it.id!)
              })
            : context.selectedTeam && code
              ? applyWithCode(context.selectedTeam, code)
              : context.selectedTeam
                ? apply(context.selectedTeam)
                : createTeam(
                    competitionId!,
                    {
                      representing: context.representing,
                      alternateName: context.alternateName!,
                      joinRestriction: context.joinRestriction!,
                      leader: {
                        participant: context.user?.id,
                        player: !context.nonParticipantLeader
                      },
                      lookingForMore:
                        context.joinRestriction === JoinRestriction.INVITEONLY
                          ? false
                          : context.lookingForMore
                    },
                    true
                  )
        )
    }
  })

  // log competition join flow events for analytics
  useEffect(() => {
    logEvent(`competition_join_flow`, { step: state.value })
  }, [state, logEvent])

  // passing undefined for back will prevent it from rendering when unavailable as an action
  const handleBack = state.can('BACK') ? () => send('BACK') : undefined

  return (
    <RcSuspense height={400}>
      {state.matches('reviewRules') ? (
        <>
          <Rules />
          <ModalConfiguration
            title={
              <RcTrans i18nKey='competition:join-flow.review-rules-title' />
            }
            topAction={handleBack}
          >
            <RcButton
              fullWidth
              variant='contained'
              onClick={() => {
                send('SET_REVIEWED')
              }}
            >
              <RcTrans i18nKey='shared.i-understand-and-agree' />
            </RcButton>
          </ModalConfiguration>
        </>
      ) : state.matches('setCommunity') ? (
        <SetCommunity
          context={state.context}
          onSelected={context => send('SET_COMMUNITY', context)}
          onBack={handleBack}
        />
      ) : state.matches('setMembers') ? (
        <SetMembers
          context={state.context}
          onSelected={context => send('SET_MEMBERS', context)}
          onBack={handleBack}
        />
      ) : state.matches('setTeam') ? (
        <SetTeam
          context={state.context}
          processing={state.matches('setTeam.joining')}
          onCreated={context => send('SET_TEAM', context)}
          onBack={handleBack}
        />
      ) : state.matches('setHandle') ? (
        <SetHandle
          context={state.context}
          processing={state.matches('setHandle.joining')}
          onHandleSet={() => send('SET_HANDLE')}
          onBack={handleBack}
        />
      ) : state.matches('findCommunity') ? (
        <FindCommunity />
      ) : state.matches('failure') ? (
        <>
          <Typography variant='body0'>
            {first(state.context.error?.issues)?.message}
          </Typography>
          <ModalConfiguration topAction={handleBack}>
            <ContactFlowLink>
              <RcButton fullWidth variant='contained'>
                <RcTrans i18nKey='shared.get-help-button' />
              </RcButton>
            </ContactFlowLink>
          </ModalConfiguration>
        </>
      ) : (
        <RcLoader height={400} />
      )}
    </RcSuspense>
  )
}
