import { CompetitionBracketResource } from '@rallycry/api-suite-typescript/dist/models/CompetitionBracketResource'
import { EventKind } from '@rallycry/api-suite-typescript/dist/models/EventKind'
import { MatchState } from '@rallycry/api-suite-typescript/dist/models/MatchState'
import { first, isEmpty } from 'lodash-es'
import {
  BracketKind,
  BracketState
} from '@rallycry/api-suite-typescript/dist/models'
import { RcTrans } from '@/components/atoms/RcTrans'
import { RcButton } from '@/components/molecules/interactive/RcButton'
import { Countdown } from '@/components/molecules/text/Countdown'
import { ModalTrigger } from '@/components/organisms/modal/ModalTrigger'
import { ExpansionType, expand, expandById, expander } from '@/core/expand'
import { useNavigation } from '@/core/hooks/useNavigation'
import { useTime } from '@/core/hooks/useTime'
import {
  CompetitionInfoRoute,
  CompetitionRoute,
  RootRoute
} from '@/core/route-keys'
import { useBracketMatchesPersonal } from '@/entity/bracket-match/useBracketMatchesPersonal'
import { useCompetition } from '@/entity/competition/useCompetition'
import { useCompetitionEvents } from '@/entity/competition/useCompetitionEvents'
import { useCompetitionParticipant } from '@/entity/competition/useCompetitionParticipant'
import {
  TutorialItem,
  TutorialType
} from '@/entity/competition/useCompetitionTutorial'
import { MatchResultsFlow } from '@/flows/Competition/MatchResultsFlow/_MatchResultsFlow'
import { useBrackets } from '@/entity/bracket/useBrackets'

export const useCompetitionAlertsMatch = () => {
  const { navTo } = useNavigation()
  const { getNow } = useTime()
  const { brackets } = useBrackets()
  const { competition } = useCompetition()
  const { isPlayer } = useCompetitionParticipant()
  const { events, refresh } = useCompetitionEvents()
  const { personal } = useBracketMatchesPersonal()

  const issues: TutorialItem[] = []

  // user is not participating, skip the rest
  if (!isPlayer()) return issues

  const now = getNow()

  // filter out registration, checkin & rosterlock which are handled separately
  const allEvents = events(undefined, [
    EventKind.REGISTRATION,
    EventKind.CHECKIN,
    EventKind.ROSTERLOCK
  ])
  const matchEvents = events([EventKind.MATCH])
  const activeMatches = personal.filter(it => it.state !== MatchState.COMPLETE)
  const activeLadder = brackets?.find(
    it =>
      it.state === BracketState.STARTED &&
      expand(it, it._expanded, ExpansionType.CompetitionBracketSettings)
        ?.kind === BracketKind.LADDER
  )

  const evt = allEvents.current || allEvents.next
  // no matches require action, display an alert for current/next event
  if (isEmpty(activeMatches) && evt) {
    if (allEvents.current?.kind === EventKind.LADDER && activeLadder) {
      // handle the case where the current active event is for a ladder
      issues.push({
        header: <RcTrans i18nKey='competition:issues.ladder-header' />,
        type: TutorialType.TASK,
        severity: 'info',
        children: <RcTrans i18nKey='competition:issues.ladder-active' />,
        onClick: () =>
          navTo({
            root: RootRoute.Competition,
            rootId: competition?.id,
            subRoute: CompetitionRoute.Bracket,
            subId: activeLadder?.id
          })
      })
    } else {
      issues.push({
        header: evt?.name,
        type: TutorialType.TASK,
        onClick: () =>
          navTo(
            {
              root: RootRoute.Competition,
              rootId: competition?.id,
              subRoute: CompetitionRoute.Info,
              subId: CompetitionInfoRoute.Details,
              query: 'calendarScrollTo=true'
            },
            true
          ),
        severity: 'info',
        children: allEvents.current ? (
          <>
            {allEvents.current?.description
              ? `${allEvents.current?.description}`
              : ''}
          </>
        ) : (
          <>
            <RcTrans
              i18nKey='competition:issues.upcoming-event'
              tOptions={{ name: allEvents.next?.name }}
            />

            {allEvents.next?.description
              ? `. ${allEvents.next?.description}`
              : ''}
          </>
        ),
        action: (
          <Countdown
            target={allEvents.current?.endDate || allEvents.next?.startDate}
            onComplete={refresh}
          />
        )
      })
    }
  }
  // multiple matches active collapse to 1 issue and goto "my matches" view
  else if (activeMatches.length > 1) {
    issues.push({
      header: <RcTrans i18nKey='competition:issues.multiple-matches-active' />,
      type: TutorialType.TASK,
      onClick: () =>
        navTo(
          {
            root: RootRoute.Competition,
            rootId: competition?.id,
            subRoute: CompetitionRoute.Matches
          },
          true
        ),
      severity: 'info',
      children: <RcTrans i18nKey='competition:issues.several-matches-active' />
    })
  }
  // just 1 match, detailed wizard display
  else {
    const match = first(activeMatches)
    if (!match) return issues

    const ba1 = expandById(
      match?.assignments?.[0]?.id,
      match._expanded,
      ExpansionType.BracketAssignment
    )
    const ba2 = expandById(
      match?.assignments?.[1]?.id,
      match._expanded,
      ExpansionType.BracketAssignment
    )
    const team1 = expandById(
      ba1?.entry?.id,
      match._expanded,
      ExpansionType.CompetitionEntry
    )
    const team2 = expandById(
      ba2?.entry?.id,
      match._expanded,
      ExpansionType.CompetitionEntry
    )

    const matchEvent = matchEvents?.all?.find(it => it.id === match?.event?.id)
    const matchBracket = expander<CompetitionBracketResource>(
      match,
      'competitionBracket'
    )

    const bracketName = matchBracket?.disambiguatingDescription
      ? `${matchBracket?.disambiguatingDescription} - ${matchBracket?.name}`
      : matchBracket?.name

    const matchRoute = {
      root: RootRoute.Competition,
      rootId: competition?.id,
      subRoute: CompetitionRoute.Match,
      subId: match?.id
    }

    const scoreTrigger = (text: React.ReactNode) => (
      <ModalTrigger
        activation={handleOpen => (
          <RcButton size='medium' onClick={handleOpen}>
            {text}
          </RcButton>
        )}
      >
        {({ handleClose }) => (
          <MatchResultsFlow
            matchId={match?.id}
            competitionId={competition?.id}
            onComplete={handleClose}
          />
        )}
      </ModalTrigger>
    )

    // disputed (could not agree)
    if (!!match?.dateDisputed && !match.dateResolved) {
      issues.push({
        type: TutorialType.ALERT,
        severity: 'error',
        children: <RcTrans i18nKey='competition:issues.match-disputed' />
      })
    }
    // conflicted (chance to agree)
    else if (!!match?.dateConflicted && !match.dateResolved) {
      issues.push({
        type: TutorialType.ALERT,
        severity: 'warning',
        children: <RcTrans i18nKey='competition:issues.match-conflicted' />
      })
    }
    // scores pending finalization
    else if (match.negotiableUntil && now.isBefore(match.negotiableUntil)) {
      issues.push({
        header: <RcTrans i18nKey='competition:issues.match-pending-header' />,
        type: TutorialType.TASK,
        onClick: () => navTo(matchRoute, true),
        severity: 'info',
        children: (
          <RcTrans i18nKey='competition:issues.match-pending-finalization' />
        ),
        action: scoreTrigger(
          <RcTrans i18nKey='competition:issues.match-review-scores' />
        )
      })
    }
    // match scheduled in future
    else if (matchEvent?.startDate && now.isBefore(matchEvent?.startDate)) {
      issues.push({
        header: <RcTrans i18nKey='competition:issues.match-coming-up-header' />,
        type: TutorialType.TASK,
        onClick: () => navTo(matchRoute, true),
        severity: 'info',
        children: (
          <RcTrans
            i18nKey='competition:issues.match-coming-up'
            tOptions={{
              bracketName,
              team1: team1?.alternateName,
              team2: team2?.alternateName
            }}
          />
        ),
        action: (
          <Countdown target={matchEvent?.startDate} onComplete={refresh} />
        )
      })
    }
    // no opponent set for match
    else if (match.assignments?.length === 1) {
      issues.push({
        header: (
          <RcTrans i18nKey='competition:issues.match-no-opponent-header' />
        ),
        type: TutorialType.TASK,
        dismissable: `${competition?.id}-${match?.id}-no-opponent`,
        severity: 'info',
        children: (
          <RcTrans i18nKey='competition:issues.match-no-opponent-assigned' />
        )
      })
    }
    // default case - scores needed
    else {
      issues.push({
        header: (
          <RcTrans i18nKey='competition:issues.match-scores-needed-header' />
        ),
        type: TutorialType.TASK,
        onClick: () => navTo(matchRoute, true),
        severity: 'info',
        children: (
          <RcTrans
            i18nKey='competition:issues.match-scores-needed'
            tOptions={{
              bracketName,
              team1: team1?.alternateName,
              team2: team2?.alternateName
            }}
          />
        ),
        action: scoreTrigger(
          <RcTrans i18nKey='competition:issues.match-report-scores' />
        )
      })
    }
  }

  return issues
}
