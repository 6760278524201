import { RcIcon } from '@/components/atoms/RcIcon'
import { SearchAvatar } from '@/components/organisms/avatar/SearchAvatar'
import { useApiError } from '@/core/hooks/useApiError'
import Autocomplete from '@mui/material/Autocomplete'
import Chip from '@mui/material/Chip'
import FormHelperText from '@mui/material/FormHelperText'
import TextField from '@mui/material/TextField'
import { difference, isArray, some } from 'lodash-es'
import React, { useEffect, useState } from 'react'
import { RcAvatar } from '../text/RcAvatar'

export const EntityAutocomplete = <
  T extends { id?: any; name?: string; image?: string }
>({
  multiple,
  error,
  isValidating,
  options,
  selected,
  disabled,
  disabledOptions,
  placeholder,
  fallbackImage,
  noOptionsText,
  disableClearable,
  enableReinitialize,
  variant = 'rounded',
  onSearch,
  onLoadOptions,
  onChange
}: {
  multiple?: boolean
  error?: string
  isValidating: boolean
  options: T[]
  selected: any[]
  disabled?: boolean
  disabledOptions?: any[]
  placeholder?: string
  fallbackImage?: string
  noOptionsText?: (
    handleClose: () => void,
    inputValue?: string
  ) => React.ReactNode
  disableClearable?: boolean
  enableReinitialize?: boolean
  variant?: 'square' | 'rounded' | 'circular'
  onSearch: (q: string) => any
  onLoadOptions: () => Promise<any>
  onChange: (
    updated: T | NonNullable<T> | T[] | null,
    added: any[],
    removed: any[]
  ) => Promise<any>
}) => {
  const [open, setOpen] = useState(false)

  // state for the autocomplete text field
  const [inputValue, setInputValue] = useState<string | undefined>(undefined)

  // state for the autocomplete current selections
  const [value, setValue] = useState<T | NonNullable<T> | T[] | null>(
    some(selected)
      ? multiple
        ? options.filter(it => selected.includes(it.id!))
        : options.find(it => selected.includes(it.id!))!
      : multiple
        ? []
        : null
  )

  const [isSaving, setIsSaving] = useState(false)
  const { handle } = useApiError()

  const handleSearch = async (q: string) => {
    setInputValue(q)
    onSearch(q)
  }

  useEffect(() => {
    const val = some(selected)
      ? multiple
        ? options.filter(it => selected.includes(it.id!))
        : options.find(it => selected.includes(it.id!))!
      : multiple
        ? []
        : null
    enableReinitialize && setValue(val)
  }, [enableReinitialize, selected, options, multiple])

  const handleClose = () => {
    setOpen(false)
    setInputValue('')
  }

  const handleUpdate = async (
    newValues: T | NonNullable<T> | T[] | null,
    reason: any,
    details: any
  ) => {
    try {
      setValue(newValues)
      setIsSaving(true)

      const all = isArray(newValues)
        ? newValues?.map(it => it.id!)
        : [newValues?.id!]
      const added = all.filter(it => !selected.includes(it))
      const removed = difference(selected, all)

      await onChange(newValues, added, removed)
      setIsSaving(false)
    } catch (error) {
      await handle(error)
    }
  }

  return (
    <Autocomplete
      open={open}
      onOpen={() => setOpen(true)}
      onClose={handleClose}
      multiple={multiple}
      value={value || null}
      onChange={(_, newValues, reason, details) => {
        handleUpdate(newValues, reason, details)
      }}
      disabled={disabled || isSaving}
      inputValue={inputValue}
      onInputChange={(_, val) => handleSearch(val)}
      getOptionLabel={option => option.name || ''}
      isOptionEqualToValue={(option, value) => option.id === value.id}
      loading={isValidating && !some(options)}
      options={options}
      disableClearable={disableClearable}
      noOptionsText={noOptionsText?.(handleClose, inputValue)}
      clearOnBlur
      renderOption={(props, option) => (
        <li {...props} key={option.id}>
          <SearchAvatar
            resource={option}
            fallbackImage={fallbackImage}
            variant={variant}
          />
        </li>
      )}
      renderInput={(params: any) => (
        <>
          <TextField
            error={!!error}
            variant='outlined'
            disabled={isSaving}
            placeholder={placeholder}
            {...params}
            slotProps={{
              input: {
                ...params?.InputProps,
                startAdornment: (
                  <>
                    <RcIcon icon={['fal', 'search']} size='sm' />
                    {params?.InputProps.startAdornment}
                  </>
                )
              }
            }}
          />
          {error ? (
            <FormHelperText error sx={{ ml: 4 }}>
              {error}
            </FormHelperText>
          ) : undefined}
        </>
      )}
      renderTags={(tagValue, getTagProps) =>
        tagValue.map((option, index) => (
          <Chip
            label={option.name}
            size='small'
            {...getTagProps({ index })}
            key={index}
            disabled={disabledOptions?.includes(option.id!)}
            avatar={
              option.image ? (
                <RcAvatar
                  alt={option.name}
                  src={option.image}
                  variant={variant}
                />
              ) : undefined
            }
          />
        ))
      }
      ListboxProps={{
        onScroll: (event: React.SyntheticEvent) => {
          const listboxNode = event.currentTarget
          if (
            Math.ceil(listboxNode.scrollTop) + listboxNode.clientHeight >=
            listboxNode.scrollHeight - 2
          ) {
            onLoadOptions()
          }
        }
      }}
    />
  )
}
