import Chip from '@mui/material/Chip'
import Stack from '@mui/material/Stack'
import TableCell from '@mui/material/TableCell'
import TableRow from '@mui/material/TableRow'
import { FormResponseResource } from '@rallycry/api-suite-typescript/dist/models/FormResponseResource'
import { FormResponseAdhoc } from '../Response/FormResponseAdhoc'
import { ConfirmingButton } from '@/components/molecules/interactive/ConfirmingButton'
import { RcIconButton } from '@/components/molecules/interactive/RcIconButton'
import { UserAvatar } from '@/components/organisms/avatar/UserAvatar'
import { NavigationLink } from '@/components/organisms/navigation/NavigationLink'
import { RcTrans } from '@/components/atoms/RcTrans'
import { expand, ExpansionType } from '@/core/expand'
import { RootRoute } from '@/core/route-keys'
import { useOrganization } from '@/entity/organization/useOrganization'
import { useNavigation } from '@/core/hooks/useNavigation'

export const FormResponsesRow = ({
  response,
  onComplete,
  onRemove
}: {
  response: FormResponseResource
  onComplete: () => Promise<any>
  onRemove: () => Promise<any>
}) => {
  const { isOrgModerator } = useOrganization()
  const { getPath } = useNavigation()

  const user = expand(response, response._expanded, ExpansionType.User)

  return (
    <TableRow key={response.id}>
      <TableCell>
        {user ? (
          <UserAvatar user={user!} />
        ) : (
          expand(response, response._expanded, ExpansionType.CuratedForm)?.name
        )}
      </TableCell>
      <TableCell align='right'>
        <Stack
          direction='row'
          justifyContent='flex-end'
          alignItems='center'
          spacing={1}
        >
          {response.dateCompleted ? (
            <Chip
              label={<RcTrans i18nKey='shared.completed' />}
              color='success'
            />
          ) : response.assignedSection ? (
            <Chip
              label={<RcTrans i18nKey='shared.assigned' />}
              color='warning'
            />
          ) : (
            <Chip
              label={<RcTrans i18nKey='shared.incomplete' />}
              color='error'
            />
          )}
          <NavigationLink
            to={getPath({ root: RootRoute.FormResponse, rootId: response.id })}
          >
            <RcIconButton icon={['fal', 'eye']} size='small' />
          </NavigationLink>
          <FormResponseAdhoc
            activation={(handleOpen: () => void) => (
              <RcIconButton
                disabled={!!response.dateCompleted}
                icon={['fal', 'pencil']}
                size='small'
                onClick={handleOpen}
              />
            )}
            onComplete={onComplete}
            formId={response?.form?.id!}
          />
          {isOrgModerator ? (
            <ConfirmingButton
              icon={['fal', 'trash']}
              onClick={() => onRemove()}
              message={<RcTrans i18nKey='shared.delete-confirmation' />}
              buttonName={<RcTrans i18nKey='shared.delete' />}
              size='small'
            />
          ) : null}
        </Stack>
      </TableCell>
    </TableRow>
  )
}
