import Box, { BoxProps } from '@mui/material/Box'
import Grid from '@mui/material/Grid'
import Stack from '@mui/material/Stack'
import Typography, {
  TypographyPropsVariantOverrides
} from '@mui/material/Typography'
import { some } from 'lodash-es'
import {
  TypographyVariant,
  TypographyVariantsOptions
} from '@mui/material/styles'
import { RcIconButton } from '../interactive/RcIconButton'

type FilterConditionally<T, U> = {
  [K in keyof T]: T[K] extends U ? K : never
}[keyof T]
type OverridableStringUnion<T extends string | number | symbol, U = object> =
  | FilterConditionally<U, true>
  | Exclude<T, FilterConditionally<U, false>>

export interface SectionHeaderProps extends Omit<BoxProps, 'title'> {
  title?: React.ReactNode
  subtitle?: React.ReactNode
  tooltips?: { kind?: 'warning' | 'info'; content: React.ReactNode }[]
  size?: OverridableStringUnion<
    'inherit' | TypographyVariant,
    TypographyPropsVariantOverrides
  >
}

export const SectionHeader: React.FC<SectionHeaderProps> = ({
  children,
  title,
  subtitle,
  tooltips,
  size,
  ...BoxProps
}) => {
  if (!title && !subtitle) return null
  return (
    <Box {...BoxProps}>
      <Grid
        container
        direction='row'
        alignItems='center'
        justifyContent='space-between'
        spacing={3}
      >
        <Grid item>
          <Stack direction='row' alignItems='center'>
            <Typography variant={size || 'h3'}>{title}</Typography>

            {tooltips?.map((it, idx) => (
              <RcIconButton
                key={idx}
                size='small'
                color='info'
                icon={
                  it.kind === 'warning'
                    ? ['fal', 'exclamation-triangle']
                    : ['fal', 'info-circle']
                }
                TooltipProps={
                  it.content
                    ? {
                        title: it.content,
                        enterTouchDelay: 0,
                        arrow: true
                      }
                    : undefined
                }
              />
            ))}
          </Stack>

          {subtitle ? (
            <Box pt={some(tooltips) ? 0 : 1}>
              <Typography color='textSecondary'>{subtitle}</Typography>
            </Box>
          ) : null}
        </Grid>
        {children ? (
          <Grid item xs justifyContent='flex-end'>
            {children}
          </Grid>
        ) : null}
      </Grid>
    </Box>
  )
}
