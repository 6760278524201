import { Theme } from '@mui/material/styles'
import { Expanded } from '@rallycry/api-suite-typescript/dist/models/Expanded'
import { partition, some } from 'lodash-es'
import { useState, useEffect } from 'react'
import { ScoreCount } from '@rallycry/social-api-typescript'
import { WINDOW } from '../../organizations.config'
import { ErrorCode } from './error-code'

/*
 * Converts a user to their display string based on availability of proper name or handle.
 */
export const getUserDisplayName = (user?: {
  id?: number
  name?: string
  givenName?: string
  familyName?: string
}) => {
  if (!user) return ''
  const { name, givenName, familyName } = user
  return `${givenName && familyName ? [givenName, familyName].join(' ') : name}`
}

export const trimTrailingSlash = (input?: string) =>
  input?.replace(/\/$/, '') || ''

// get keys of object typed for use in later indexing of object
export const keysOf = <T>(obj: T): Array<keyof T> =>
  Array.from(Object.keys(obj as any)) as any

export const tryExtractJsonError = async (
  error: any
): Promise<IssueErrorType> => {
  try {
    return await error?.clone?.()?.json()
  } catch (jsonErr) {
    console.log(error)
    return {
      issues: [
        {
          code: ErrorCode.Unknown,
          field: 'unknown',
          message: error.message || error.status
        }
      ]
    }
  }
}

// wrap service calls with error extractor for xState
export const invokeService = async (fn: () => any) => {
  try {
    const res = await fn()
    return res
  } catch (error) {
    const awaited = await tryExtractJsonError(error as any)
    throw awaited
  }
}

export type IssueErrorType = {
  issues?: {
    code: ErrorCode
    field: string
    rejectedValue?: string
    context?: any
    message: string
  }[]
}

// extract issues from API into a format that Formik can handle
export const handleIssues = (
  error: IssueErrorType | undefined,
  knownFields?: string[]
) => {
  if (!some(error?.issues)) return {}

  const [handled, unhandled] = partition(error?.issues, it =>
    knownFields?.includes(it.field)
  )

  const initialErrors = handled?.reduce(
    (acc, val) => {
      acc[val.field] = `${val.message} (${val.code})`
      return acc
    },
    {} as Record<string, string>
  )

  const initialTouched = handled?.reduce(
    (acc, val) => {
      acc[val.field] = true
      return acc
    },
    {} as Record<string, boolean>
  )

  return { initialErrors, initialTouched, unhandled }
}

export const flatspand = <T extends Record<string, any>>(
  it?: { _expanded?: Expanded }[],
  target?: keyof Expanded
) => (it?.map(it => it._expanded?.[target!] || []).flat() || []) as T[]

export const isDarkMode = (theme: Theme) => theme.palette.mode === 'dark'

// convert number to place e.g. 1 -> 1st, 2 -> 2nd, 3 -> 3rd etc
// not very i18n friendly :-/
export const toOrdinal = (n?: number) => {
  if (!n) return ''
  const s = ['th', 'st', 'nd', 'rd'],
    v = n % 100
  return n + (s[(v - 20) % 10] || s[v] || s[0])
}

export const scrollIntoViewWithOffset = (
  element?: HTMLElement,
  offset: number = 0
) => {
  if (!element) return

  element.scrollIntoView({
    behavior: 'smooth'
  })
}

// converts ".gg" -> "[.]gg$"
// converts "*.gg" -> "(.*)[.]gg$"
export const getDomainRegex = (domain: string) => {
  domain = domain.replace('.', '[.]')
  domain = domain.replace('*', '(.*)')
  domain += '$'
  return new RegExp(domain)
}

export const isDomainMatch = (
  value: string | undefined,
  domains: string[],
  include: boolean
) => {
  if (!domains || domains.length === 0) return true
  if (!value) return true

  const splits = value?.toLowerCase()?.split('@')
  if (splits?.length > 1) {
    const matchers = domains.map(d => getDomainRegex(d))
    const hasMatch = some(matchers, it => it.test(splits[1]))
    return include ? hasMatch : !hasMatch
  }

  return true
}

export const useIsMac = () => {
  const [isMac, setIsMac] = useState(false)

  useEffect(() => {
    setIsMac(window.navigator.platform.includes('Mac'))
  }, [])

  return isMac
}
export const randomInteger = (min: number, max: number) => {
  return Math.floor(Math.random() * (max - min + 1)) + min
}
export const calculateWinRate = (matchData: ScoreCount[]) => {
  const wins = matchData?.find(it => it.score === 1)?.count || 0
  const draws = matchData?.find(it => it.score === 0.5)?.count || 0
  const losses = matchData?.find(it => it.score === 0)?.count || 0
  const hasPlayed = wins + losses + draws > 0
  const winRate = hasPlayed ? Math.round((wins / (wins + losses)) * 100) : 0

  return { wins, draws, losses, hasPlayed, winRate }
}
