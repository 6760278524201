import { LinkProps } from '@mui/material/Link'
import MatLink from '@mui/material/Link'
import Link from 'next/link'
import { forwardRef } from 'react'
import { useFirebase } from '@/components/providers/site/FirebaseProvider'

export interface NavigationLinkProps extends LinkProps {
  hover?: boolean
  to?: string
  scroll?: boolean
  googleTag?: string
  pointer?: boolean
}
/**
 * Wraps react-dom NavLinks with a Mui API.
 */
export const NavigationLink = forwardRef(function NavigationLink(
  { googleTag, scroll, hover = true, pointer, ...props }: NavigationLinkProps,
  ref: any
) {
  const { logEvent } = useFirebase()

  const handleClick = (ev: any) => {
    ev.stopPropagation()
    props?.onClick?.(ev)
    if (googleTag) {
      logEvent(googleTag)
    }
  }

  return props.to ? (
    <MatLink
      sx={pointer ? { cursor: 'pointer' } : {}}
      className={hover ? 'hover' : undefined}
      component={Link}
      prefetch={false}
      onClick={handleClick}
      underline='none'
      {...props}
      scroll={scroll}
      href={props.to || ''}
      ref={ref}
    />
  ) : (
    <MatLink
      sx={pointer ? { cursor: 'pointer' } : {}}
      className={hover ? 'hover' : undefined}
      target={props.href ? '_blank' : ''}
      rel={props.href ? 'noopener' : ''}
      onClick={handleClick}
      {...props}
      underline='none'
      href={props.href}
      ref={ref}
    />
  )
})
