import {
  BracketAssignmentControllerApi,
  QueryBracketAssignmentsRequest
} from '@rallycry/api-suite-typescript/dist/apis/BracketAssignmentControllerApi'
import { CompetitionBracketControllerApi } from '@rallycry/api-suite-typescript/dist/apis/CompetitionBracketControllerApi'
import { useMemo } from 'react'
import { useCompetitionMeta } from '../competition/useCompetitionMeta'
import { useCompetitionParticipant } from '../competition/useCompetitionParticipant'
import { EntityOptions, useQueryEntity } from '../useEntity'
import { useController } from '@/core/hooks/useSWRApi'
import { useParsedParam } from '@/core/hooks/useRouteParams'

export const useBracketAssignments = (
  options?: EntityOptions<QueryBracketAssignmentsRequest>
) => {
  const bracketId = useParsedParam('bracketId')
  const { read: competitionMeta } = useCompetitionMeta()
  const { involvement } = useCompetitionParticipant()
  const { ctrl: assignCtrl } = useController(CompetitionBracketControllerApi)
  const { ctrl } = useController(BracketAssignmentControllerApi)

  const entity = useQueryEntity({
    key: 'useBracketAssignments',
    expand: 'content{entry{leader,representing{additionalOrganizations}}}',
    paused: !bracketId,
    pageSize: 10000,
    ...options,
    request: { bracketId, ...options?.request },
    read: req => ctrl().queryBracketAssignments(req)
  })

  const assignTeams = async (ids: number[]) => {
    await assignCtrl().assignCompetitionBracket({
      id: bracketId,
      expand: '',
      CompetitionBracketAssignCommand: { entries: ids }
    })
    await entity.query.mutate()
    await competitionMeta.mutate()
  }

  const assignments = entity.flat

  const playerTeam = involvement?.find(it => !!it.entry && it.player)
  const assignment = useMemo(
    () => assignments.find(it => it.entry?.id === playerTeam?.entry?.id),
    [assignments, playerTeam]
  )

  return { ...entity, assignments, assignment, assignTeams }
}
