import Stack from '@mui/material/Stack'
import Typography from '@mui/material/Typography'
import { find } from 'lodash-es'
import { MatchResultsMachine } from './match-results-machine'
import { MatchResultFlowActions } from './MatchResultFlowActions'
import { MatchResultRow } from './MatchResultRow'
import { MatchCard } from '@/components/pages/Competition/components/match/card/MatchCard'
import { RcTrans } from '@/components/atoms/RcTrans'
import { useNetworkKinds } from '@/core/hooks/i18n/useNetworkKind'

export const Finalized = ({ machine }: { machine: MatchResultsMachine }) => {
  const [state] = machine
  const { match, bracketAssignment1, bracketAssignment2 } = state.context
  const { getNetworkString } = useNetworkKinds()

  const collection = state.context.matchGames?.map((it, idx) => ({
    ordinal: idx + 1,
    externalNetwork: it.externalNetwork,
    winner: it?.winner?.id!,
    scores: it?.scores || []
  }))

  const externalNetwork = find(
    collection,
    it => !!it.externalNetwork
  )?.externalNetwork

  return (
    <Stack direction='column' spacing={2}>
      <MatchCard match={match} competition={state.context.competition} />
      {collection?.map(it => (
        <MatchResultRow
          key={it.ordinal}
          result={it}
          isNumericMode={state.context.bracketSettings?.numericGameScore}
          bracketAssignment1={bracketAssignment1}
          bracketAssignment2={bracketAssignment2}
        />
      ))}
      {externalNetwork ? (
        <Typography
          variant='subtitle2'
          textAlign='center'
          color='text.secondary'
        >
          <RcTrans
            i18nKey='competition:match-result-external'
            tOptions={{ network: getNetworkString(externalNetwork) }}
          />
        </Typography>
      ) : null}
      <MatchResultFlowActions
        machine={machine}
        title={<RcTrans i18nKey='competition:match-results' />}
      />
    </Stack>
  )
}
