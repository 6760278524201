import Alert from '@mui/material/Alert'
import Divider from '@mui/material/Divider'
import Stack from '@mui/material/Stack'
import Typography from '@mui/material/Typography'
import { find, some, sumBy } from 'lodash-es'
import dynamic from 'next/dynamic'
import { MatchResultFlowActions } from './MatchResultFlowActions'
import { MatchResultRow } from './MatchResultRow'
import { MatchResultsMachine } from './match-results-machine'
import { MatchCard } from '@/components/pages/Competition/components/match/card/MatchCard'
import { SectionHeader } from '@/components/molecules/text/SectionHeader'
import { RcTrans } from '@/components/atoms/RcTrans'
import { useNetworkKinds } from '@/core/hooks/i18n/useNetworkKind'
import { RcIcon } from '@/components/atoms/RcIcon'

const Countdown = dynamic(
  async () => (await import('@/components/molecules/text/Countdown')).Countdown,
  { ssr: false, loading: () => <></> }
)

export const ReviewScores = ({ machine }: { machine: MatchResultsMachine }) => {
  const [state] = machine
  const { match, bracketAssignment1, bracketAssignment2 } = state.context
  const { getNetworkString } = useNetworkKinds()

  const collection =
    state.context.matchGameReports?.map((it, idx) => ({
      ordinal: idx + 1,
      externalNetwork: it.externalNetwork,
      reporter: it.entry?.id!,
      winner: it?.winner?.id!,
      scores: [it?.score1!, it.score2!]
    })) || []

  const involvement = state.context.competition?._viewInvolvement
  const selfTeams = involvement?.participation?.map(it => it.entry?.id!) || []

  const selfCollection = collection.filter(it =>
    selfTeams.includes(it.reporter)
  )
  const selfReported = some(selfCollection)
  const selfNetwork = find(
    selfCollection,
    it => !!it.externalNetwork
  )?.externalNetwork

  const theyCollection = collection.filter(it => !selfCollection.includes(it))
  const theyReported = some(theyCollection)
  const theyNetwork = find(
    theyCollection,
    it => !!it.externalNetwork
  )?.externalNetwork
  const bothReported = selfReported && theyReported

  return (
    <Stack direction='column'>
      {selfReported ? (
        <>
          <SectionHeader
            title={<RcTrans i18nKey='competition:match.your-report' />}
          />
          <Stack direction='column' spacing={5}>
            <MatchCard
              match={match}
              competition={state.context.competition}
              score1={sumBy(selfCollection, it =>
                it.scores[0] > it.scores[1] ? 1 : 0
              )}
              score2={sumBy(selfCollection, it =>
                it.scores[0] < it.scores[1] ? 1 : 0
              )}
            />
            {selfCollection?.map(it => (
              <MatchResultRow
                key={it.ordinal}
                result={it}
                isNumericMode={state.context.bracketSettings?.numericGameScore}
                bracketAssignment1={bracketAssignment1}
                bracketAssignment2={bracketAssignment2}
              />
            ))}
            {selfNetwork ? (
              <Typography
                variant='subtitle2'
                textAlign='center'
                color='text.secondary'
              >
                <RcTrans
                  i18nKey='competition:match-result-external'
                  tOptions={{ network: getNetworkString(selfNetwork) }}
                />
              </Typography>
            ) : null}
          </Stack>
        </>
      ) : null}
      {selfReported && theyReported ? <Divider sx={{ my: 5 }} /> : null}
      {theyReported ? (
        <>
          <SectionHeader
            title={<RcTrans i18nKey='competition:match.their-report' />}
          />
          <Stack direction='column' spacing={5}>
            <MatchCard
              match={match}
              competition={state.context.competition}
              score1={sumBy(theyCollection, it =>
                it.scores[0] > it.scores[1] ? 1 : 0
              )}
              score2={sumBy(theyCollection, it =>
                it.scores[0] < it.scores[1] ? 1 : 0
              )}
            />
            {theyCollection?.map(it => (
              <MatchResultRow
                key={it.ordinal}
                result={it}
                isNumericMode={state.context.bracketSettings?.numericGameScore}
                bracketAssignment1={bracketAssignment1}
                bracketAssignment2={bracketAssignment2}
              />
            ))}
            {theyNetwork ? (
              <Typography
                variant='subtitle2'
                textAlign='center'
                color='text.secondary'
              >
                <RcTrans
                  i18nKey='competition:match-result-external'
                  tOptions={{ network: getNetworkString(theyNetwork) }}
                />
              </Typography>
            ) : null}
          </Stack>
        </>
      ) : null}
      <MatchResultFlowActions
        machine={machine}
        title={<RcTrans i18nKey={'competition:match.review-scores-title'} />}
        topContent={
          <Alert
            severity='success'
            sx={{ borderRadius: 0 }}
            icon={<RcIcon icon={['fal', 'check']} size='xs' />}
          >
            <Typography variant='body1'>
              {state.matches('disputed') ? (
                <RcTrans i18nKey={'competition:match.admin-review-subtitle'} />
              ) : bothReported ? (
                <RcTrans i18nKey={'competition:match.both-reported-subtitle'} />
              ) : selfReported ? (
                <RcTrans i18nKey={'competition:match.self-reported-subtitle'}>
                  <Countdown target={state.context.match?.negotiableUntil!} />
                </RcTrans>
              ) : (
                <RcTrans i18nKey={'competition:match.they-reported-subtitle'} />
              )}
            </Typography>
          </Alert>
        }
      />
    </Stack>
  )
}
