import { RcSuspense } from '@/components/atoms/RcSuspense'
import { RcTrans } from '@/components/atoms/RcTrans'
import {
  ActionMenu,
  ActionMenuOption
} from '@/components/molecules/interactive/ActionMenu'
import { RcIconButton } from '@/components/molecules/interactive/RcIconButton'
import {
  RcTableBodyRow,
  RcTableCell,
  RcTableStickyBodyCell
} from '@/components/molecules/text/RcTable'
import { UserNetwork } from '@/components/organisms/user/UserNetwork'
import { ErrorCode } from '@/core/error-code'
import { useNetworkKinds } from '@/core/hooks/i18n/useNetworkKind'
import { useCompetitionTeam } from '@/entity/competition-team/useCompetitionTeam'
import { useCompetitionTeamContactAccounts } from '@/entity/competition-team/useCompetitionTeamContactAccounts'
import {
  TeamRank,
  useCompetitionTeamMembers
} from '@/entity/competition-team/useCompetitionTeamMembers'
import { useCompetition } from '@/entity/competition/useCompetition'
import { useCompetitionMeta } from '@/entity/competition/useCompetitionMeta'
import { useUserAccount } from '@/entity/user/useUserAccount'
import Box from '@mui/material/Box'
import Stack from '@mui/material/Stack'
import { TableRowProps } from '@mui/material/TableRow'
import { CompetitionParticipantResource } from '@rallycry/api-suite-typescript/dist/models/CompetitionParticipantResource'
import { UserResource } from '@rallycry/api-suite-typescript/dist/models/UserResource'
import { isEmpty, some } from 'lodash-es'
import { CompetitionIssueAlertTrigger } from '../alerts/CompetitionAlertFormTrigger'
import { ContactParticipantsModal } from '../shared/contact/ContactParticipantsModal'
import { TeamRosterUser } from './TeamRosterUser'
import { rank } from 'd3'
import { useCompetitionTeamInvitations } from '@/entity/competition-team/useCompetitionTeamInvitations'

export interface TeamRosterRowProps extends TableRowProps {
  participant?: CompetitionParticipantResource
  user: UserResource & { rank: TeamRank }
  isSoloCompetition: boolean
  canEdit: boolean
  canTransfer: boolean
}

export const TeamRosterRow = ({
  participant,
  user,
  isSoloCompetition,
  canEdit,
  canTransfer,
  ...rest
}: TeamRosterRowProps) => {
  const isMemberOfTeam = !!participant?.id
  const participantId = participant?.id!

  const { updateMember, removeMember, transferLeadership } =
    useCompetitionTeamMembers()

  const { revoke } = useCompetitionTeamInvitations()

  const options: ActionMenuOption[] = []

  if (user.rank === TeamRank.HeadCaptain || user.rank === TeamRank.HeadCoach) {
    if (user.rank === TeamRank.HeadCaptain) {
      options.push({
        key: 'make-coach',
        display: <RcTrans i18nKey='competition:team.make-coach' />,
        action: async () =>
          updateMember(participantId, { player: false, management: true })
      })
    }

    if (user.rank === TeamRank.HeadCoach) {
      options.push({
        key: 'make-captain',
        display: <RcTrans i18nKey='competition:team.make-captain' />,
        action: async () =>
          updateMember(participantId, { player: true, management: true })
      })
    }

    canTransfer &&
      options.push({
        key: 'transfer-leadership',
        display: (
          <RcTrans i18nKey='competition:team.transfer-leadership-hint' />
        ),
        disabled: true
      })
  } else if (user.rank == TeamRank.Invited) {
    options.push({
      key: 'remove invite',
      display: <RcTrans i18nKey='competition:team.revoke-invite' />,
      action: async () => {
        await revoke(participantId)
      }
    })
  } else {
    if (user.rank !== TeamRank.Captain) {
      options.push({
        key: 'make-captain',
        display: <RcTrans i18nKey='competition:team.make-captain' />,
        action: async () =>
          updateMember(participantId, { player: true, management: true })
      })
    }

    if (user.rank !== TeamRank.Coach) {
      options.push({
        key: 'make-coach',
        display: <RcTrans i18nKey='competition:team.make-coach' />,
        action: async () =>
          updateMember(participantId, { player: false, management: true })
      })
    }

    if (user.rank !== TeamRank.Member) {
      options.push({
        key: 'make-player',
        display: <RcTrans i18nKey='competition:team.make-player' />,
        action: async () =>
          updateMember(participantId, { player: true, management: false })
      })
    }

    if (user.rank !== TeamRank.Substitute) {
      options.push({
        key: 'make-sub',
        display: <RcTrans i18nKey='competition:team.make-sub' />,
        action: async () =>
          updateMember(participantId, { player: false, management: false })
      })
    }

    options.push({
      key: 'transfer-leadership',
      display: <RcTrans i18nKey='competition:team.transfer-leadership' />,
      action: async () => transferLeadership(participantId)
    })

    options.push({
      key: 'kick',
      display: <RcTrans i18nKey='competition:team.kick' />,
      action: async () => removeMember(participantId)
    })
  }

  return (
    <RcTableBodyRow {...rest}>
      <RcTableStickyBodyCell>
        <Box pl={3}>
          <TeamRosterUser user={user} />
        </Box>
      </RcTableStickyBodyCell>

      {isMemberOfTeam ? (
        <RcTableCell sx={{ width: '100%' }}>
          <Stack
            direction='row'
            spacing={1}
            justifyContent='flex-end'
            alignItems='center'
          >
            <RcSuspense skipError skipLoader>
              <MissingFormIssues participant={user} />
              <MissingHandleIssue participant={user} />
            </RcSuspense>
            {isMemberOfTeam ? (
              <ContactParticipantsModal
                participants={[participantId]}
                label={
                  <RcIconButton
                    icon={['fal', 'envelope']}
                    TooltipProps={{
                      title: (
                        <RcTrans
                          i18nKey='competition:contact.modal.title'
                          tOptions={{ count: 1 }}
                        />
                      )
                    }}
                  />
                }
              />
            ) : null}
            {canEdit && isMemberOfTeam && some(options) ? (
              <ActionMenu options={options}>
                <RcIconButton
                  icon={['fal', 'ellipsis']}
                  TooltipProps={{
                    title: <RcTrans i18nKey='shared.actions' />,
                    arrow: true
                  }}
                />
              </ActionMenu>
            ) : null}
          </Stack>
        </RcTableCell>
      ) : null}
    </RcTableBodyRow>
  )
}

const MissingHandleIssue = ({ participant }: { participant: UserResource }) => {
  const { team } = useCompetitionTeam()
  const { getNetworkString } = useNetworkKinds()

  const issues = team?._issues?.filter(
    issue =>
      issue.code === ErrorCode.MissingRequiredNetwork &&
      issue?.context?.entryMember === participant?.id
  ) as any

  if (isEmpty(issues)) return null

  return (
    <IssueButton
      message={
        <RcTrans
          i18nKey='competition:team.contact-accounts-missing'
          tOptions={{
            missing: issues
              ?.map((it: any) =>
                getNetworkString(it.context.missingRequiredNetwork)
              )
              .join(', ')
          }}
        />
      }
    />
  )
}

const MissingFormIssues = ({
  participant
}: {
  participant: CompetitionParticipantResource
}) => {
  const { team } = useCompetitionTeam()

  const userId = participant?.id

  const formIssue = team?._issues?.find(
    issue => issue.code === ErrorCode.FormMissingResponse
  ) as any

  const missingForms = formIssue?.context?.missingResponses?.filter(
    (response: any) =>
      !response.assigned &&
      !response.completed &&
      response.participants?.find((it: any) => it === userId)
  )

  return missingForms?.map((it: any, idx: number) => (
    <MissingFormIssue key={idx} participant={participant} missingForm={it} />
  ))
}

const MissingFormIssue = ({
  missingForm,
  participant
}: {
  missingForm: any
  participant: CompetitionParticipantResource
}) => {
  const {
    read: { mutate: mutateTeam }
  } = useCompetitionTeam()
  const {
    read: { mutate: mutateCompetition }
  } = useCompetitionMeta()
  const { isCompetitionModerator } = useCompetition()

  const { account } = useUserAccount()
  const userId = participant.participant?.id
  const isSelfRow = userId === account?.id

  const response = missingForm?.responses?.find((it: any) => it.user === userId)

  const competitionFormId = missingForm?.competitionForm?.id
  const responseId = response?.id

  const formName = missingForm?.competitionForm?.formName || 'Form'

  return isSelfRow || isCompetitionModerator ? (
    <CompetitionIssueAlertTrigger
      competitionFormId={competitionFormId}
      responseId={responseId}
      onComplete={async () => {
        await mutateCompetition()
        await mutateTeam()
      }}
      activation={handleOpen => (
        <IssueButton
          onClick={handleOpen}
          message={
            <RcTrans
              i18nKey='shared.form-response-missing'
              tOptions={{ name: formName }}
            />
          }
        />
      )}
    />
  ) : (
    <IssueButton
      message={
        <RcTrans
          i18nKey='shared.form-response-missing'
          tOptions={{ name: formName }}
        />
      }
    />
  )
}

const IssueButton = ({
  onClick,
  message
}: {
  onClick?: () => any
  message: React.ReactNode
}) => {
  return (
    <RcIconButton
      onClick={onClick}
      size='small'
      color='warning'
      icon={['fal', 'exclamation-triangle']}
      TooltipProps={{
        title: message!,
        enterTouchDelay: 0,
        arrow: true
      }}
    />
  )
}

export const UserCompetitionContacts = ({ userId }: { userId?: number }) => {
  const { contacts } = useCompetitionTeamContactAccounts()

  return (
    <Stack direction='row' spacing={1}>
      {contacts
        ?.filter(it => it.user?.id === userId)
        ?.map((account, index) => (
          <UserNetwork key={index} account={account} condensed disableDelete />
        ))}
    </Stack>
  )
}
