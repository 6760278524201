import { ImageWithFallback } from '@/components/atoms/ImageWithFallback'
import { RcIcon } from '@/components/atoms/RcIcon'
import { AvatarText } from '@/components/molecules/text/AvatarText'
import { ChipGrid } from '@/components/molecules/text/ChipGrid'
import { EllipsisBox } from '@/components/molecules/text/EllipsisBox'
import { NavigationParams, useNavigation } from '@/core/hooks/useNavigation'
import Box from '@mui/material/Box'
import Card from '@mui/material/Card'
import CardActionArea from '@mui/material/CardActionArea'
import CardActions from '@mui/material/CardActions'
import CardContent from '@mui/material/CardContent'
import CardMedia from '@mui/material/CardMedia'
import { ChipProps } from '@mui/material/Chip'
import Stack from '@mui/material/Stack'
import Tooltip from '@mui/material/Tooltip'
import Typography from '@mui/material/Typography'
import { SxProps, Theme } from '@mui/material/styles'
import { isFunction, isUndefined, some } from 'lodash-es'
import { DragEventHandler, ReactNode } from 'react'
import { NavigationLink } from '../navigation/NavigationLink'

export const DiscoverCard = ({
  id,
  fab,
  header,
  headerImage,
  avatarImage,
  actions,
  title,
  titleAction,
  subtitle,
  subtitle2,
  content,
  googleTag,
  navigation,
  primaryChips,
  secondaryChips,
  expandChips,
  disabled,
  onClick,
  onDragStart,
  selected,
  padding = 0,
  sx = {}
}: {
  id?: string
  fab?: ReactNode
  header?: ReactNode
  headerImage?: string
  avatarImage?: string
  actions?: ReactNode
  title?: ReactNode
  titleAction?: ReactNode
  subtitle?: ReactNode
  subtitle2?: ReactNode
  content?: ReactNode
  googleTag?: string
  navigation?: NavigationParams | string
  primaryChips?: ChipProps[]
  secondaryChips?: ChipProps[]
  expandChips?: boolean
  onClick?: () => any
  disabled?: boolean
  onDragStart?: DragEventHandler<HTMLDivElement>
  selected?: boolean
  padding?: number
  sx?: SxProps<Theme>
}) => {
  const { getPath } = useNavigation()
  const to = getPath(navigation || '')
  const isHref = to.startsWith('http')

  const ActionAreaProps = {
    component: NavigationLink,
    googleTag,
    onClick,
    disabled,
    to: isHref ? undefined : to,
    href: isHref ? to : undefined,
    scroll: true,
    underline: 'none',
    sx: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'flex-start',
      height: '100%'
    }
  }

  return (
    <Card
      elevation={3}
      onDragStart={onDragStart}
      draggable={!!onDragStart}
      sx={theme => ({
        position: 'relative',
        width: '100%',
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        p: padding,
        borderColor: selected ? theme.palette.primary.main : undefined,
        ...((isFunction(sx) ? sx?.(theme) : sx) as any)
      })}
    >
      <CardActionArea {...ActionAreaProps} disableRipple={!!onDragStart}>
        {headerImage ? (
          <CardMedia id={id} sx={{ aspectRatio: 16 / 9, width: '100%' }}>
            <Box
              style={{ position: 'relative', width: '100%', height: '100%' }}
            >
              {headerImage.endsWith('mp4') ? (
                <video
                  playsInline
                  preload='metadata'
                  style={{
                    height: '100%',
                    width: '100%',
                    objectFit: 'cover'
                  }}
                >
                  <source src={headerImage} type='video/mp4' />
                </video>
              ) : (
                <ImageWithFallback
                  src={headerImage}
                  alt={'Header Image'}
                  style={{ objectFit: 'cover' }}
                  fill
                  priority
                />
              )}
            </Box>
          </CardMedia>
        ) : null}
        {header}
        {avatarImage || title || subtitle || subtitle2 || content ? (
          <CardContent
            sx={{
              flexGrow: 1,
              position: 'relative',
              width: '100%'
            }}
          >
            <Stack direction='column' spacing={2} height='100%'>
              {avatarImage && headerImage ? (
                <Stack
                  direction='row'
                  justifyContent='space-between'
                  display='flex'
                  sx={{
                    position: 'absolute',
                    top: avatarImage && headerImage ? -25 : 0
                  }}
                >
                  <AvatarText
                    size='large'
                    src={avatarImage}
                    variant='rounded'
                  />
                </Stack>
              ) : null}

              {title ? (
                <Stack
                  display='flex'
                  direction='row'
                  alignItems='center'
                  spacing={2}
                  pt={1}
                >
                  {avatarImage && !headerImage ? (
                    <AvatarText
                      size='medium'
                      src={avatarImage}
                      variant='rounded'
                    />
                  ) : null}

                  <Tooltip
                    title={title}
                    PopperProps={{
                      modifiers: [
                        {
                          name: 'offset',
                          options: {
                            offset: [0, -20]
                          }
                        }
                      ]
                    }}
                  >
                    <EllipsisBox lineClamp={2} breakAll={false}>
                      <Typography
                        variant='body0'
                        color='text.primary'
                        flexGrow={1}
                      >
                        {title}
                      </Typography>
                    </EllipsisBox>
                  </Tooltip>
                  {titleAction}
                </Stack>
              ) : null}

              {subtitle ? (
                <Typography variant='subtitle1' color='text.secondary'>
                  {subtitle}
                </Typography>
              ) : null}

              {subtitle2 ? (
                <Typography variant='subtitle1' color='text.secondary'>
                  {subtitle2}
                </Typography>
              ) : null}

              {some(primaryChips) ? (
                <Box>
                  <ChipGrid
                    chipProps={primaryChips}
                    color='primary'
                    expandChips={expandChips}
                  />
                </Box>
              ) : null}

              {some(secondaryChips) ? (
                <Box>
                  <ChipGrid
                    chipProps={secondaryChips}
                    color='secondary'
                    expandChips={expandChips}
                  />
                </Box>
              ) : null}

              {content}
            </Stack>
          </CardContent>
        ) : null}
        {actions ? (
          <CardActions
            sx={{ justifySelf: 'flex-end', width: '100%', pb: 4 }}
            onClick={ev => ev.stopPropagation()}
          >
            <Stack
              direction='row'
              justifyContent={'space-between'}
              alignItems={'center'}
              spacing={2}
              mx={2}
              sx={{ height: '100%', width: '100%' }}
            >
              {actions}
            </Stack>
          </CardActions>
        ) : null}
      </CardActionArea>
      <Box
        sx={theme => ({
          position: 'absolute',
          top: `calc(${theme.spacing(2)} + ${theme.spacing(padding)})`,
          right: `calc(${theme.spacing(3)} + ${theme.spacing(padding)})`
        })}
      >
        {isUndefined(selected) ? (
          <Box onClick={e => e.preventDefault()}>{fab}</Box>
        ) : (
          <RcIcon
            cursor='pointer'
            icon={selected ? ['fal', 'circle-check'] : ['fal', 'circle']}
            backgroundColor='primary'
            size='2x'
          />
        )}
      </Box>
    </Card>
  )
}
