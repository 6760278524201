import { RcIcon } from '@/components/atoms/RcIcon'
import { RcTrans } from '@/components/atoms/RcTrans'
import { RcPaperButton } from '@/components/molecules/interactive/RcPaperButton'
import { TitleParagraph } from '@/components/molecules/text/TitleParagraph'
import { ModalConfiguration } from '@/components/organisms/modal/ModalConfiguration'
import { DiscordTransLink } from '@/components/organisms/site/DiscordTransLink'
import { usePage } from '@/components/providers/site/PageProvider'
import { useUserAccount } from '@/entity/user/useUserAccount'
import Box from '@mui/material/Box'
import Grid from '@mui/material/Grid'
import Stack from '@mui/material/Stack'
import Typography from '@mui/material/Typography'
import React from 'react'
import { ContactFlowStep } from './_ContactFlow'

export const SelectContact: React.FC<{
  onSelected: (step: ContactFlowStep) => void
}> = ({ onSelected }) => {
  const { user } = useUserAccount()
  const { contact } = usePage()

  const handleSelection = (value: ContactFlowStep) => {
    onSelected(value)
  }

  return (
    <Grid container direction='column' wrap='nowrap' spacing={4}>
      {user && contact ? (
        <Grid item>
          <RcPaperButton onClick={() => handleSelection('ticket')}>
            <Stack display='flex' flexDirection='column' alignItems='center'>
              <RcIcon icon={['fal', 'comment-lines']} size='lg' />

              <TitleParagraph
                align='center'
                title={contact.title}
                paragraph={contact.subtitle}
              />
            </Stack>
          </RcPaperButton>
        </Grid>
      ) : null}

      <Grid item>
        <RcPaperButton onClick={() => handleSelection('other')}>
          <Box
            display='flex'
            flexDirection='column'
            alignItems='center'
            sx={{ py: 3 }}
          >
            <RcIcon mb={3} icon={['fal', 'comment-lines']} size='lg' />

            <TitleParagraph
              align='center'
              title={<RcTrans i18nKey='contact:other.label' />}
              paragraph={<RcTrans i18nKey='contact:other.label-help' />}
            />
          </Box>
        </RcPaperButton>
      </Grid>

      <Grid item>
        <RcPaperButton onClick={() => handleSelection('report')}>
          <Box
            display='flex'
            flexDirection='column'
            alignItems='center'
            sx={{ py: 3 }}
          >
            <RcIcon mb={3} icon={['fal', 'ban']} size='lg' />
            <TitleParagraph
              align='center'
              title={<RcTrans i18nKey='shared.report-player' />}
              paragraph={<RcTrans i18nKey='contact:report.label-help' />}
            />
          </Box>
        </RcPaperButton>
      </Grid>
      <Grid item>
        <RcPaperButton onClick={() => handleSelection('selectFeedback')}>
          <Box
            display='flex'
            flexDirection='column'
            alignItems='center'
            sx={{ py: 3 }}
          >
            <RcIcon mb={3} icon={['fal', 'download']} size='lg' />

            <TitleParagraph
              align='center'
              title={<RcTrans i18nKey='contact:give-feedback.label' />}
              paragraph={<RcTrans i18nKey='contact:give-feedback.help' />}
            />
          </Box>
        </RcPaperButton>
      </Grid>
      <Grid item />
      <ModalConfiguration
        title={
          <Typography variant='h1'>
            <RcTrans i18nKey='contact:contact.label' />
          </Typography>
        }
        subtitle={
          <Typography
            variant='body1'
            textAlign='center'
            sx={{ textWrap: 'pretty' }}
            color='text.secondary'
          >
            <RcTrans i18nKey='contact:menu.help' />{' '}
            <DiscordTransLink override={contact?.discord} />
          </Typography>
        }
      />
    </Grid>
  )
}
