import { RcSuspense } from '@/components/atoms/RcSuspense'
import { RcTrans } from '@/components/atoms/RcTrans'
import { UserAvatar } from '@/components/organisms/avatar/UserAvatar'
import { UserNetwork } from '@/components/organisms/user/UserNetwork'
import { useCompetitionTeamContactAccounts } from '@/entity/competition-team/useCompetitionTeamContactAccounts'
import { TeamRank } from '@/entity/competition-team/useCompetitionTeamMembers'
import { IconProp } from '@fortawesome/fontawesome-svg-core'
import { ChipProps } from '@mui/material/Chip'
import Stack from '@mui/material/Stack'
import { UserResource } from '@rallycry/api-suite-typescript'

interface TeamRosterUserProps {
  user?: UserResource & { rank?: TeamRank }
  hideChip?: boolean
}

export const TeamRosterUser = ({ user, hideChip }: TeamRosterUserProps) => {
  if (!user) return null
  const userChips: ChipProps[] = []
  // Add chip for user rank if not a member
  if (!hideChip && user.rank !== TeamRank.Member) {
    userChips.push({ size: 'small', label: getRosterRoleText(user.rank) })
  }

  return (
    <UserAvatar
      user={user}
      extra={
        <RcSuspense height={20}>
          <UserCompetitionContacts userId={user?.id} />
        </RcSuspense>
      }
      chipProps={hideChip ? undefined : userChips}
    />
  )
}

export const getRosterBadgeIcon = (rank?: TeamRank) =>
  rank === TeamRank.Coach || rank === TeamRank.Captain
    ? (['fal', 'web-awesome'] as IconProp)
    : undefined

export const getRosterRoleText = (rank?: TeamRank) =>
  rank === TeamRank.Coach ? (
    <RcTrans i18nKey='competition:roster.coach' />
  ) : rank === TeamRank.Captain ? (
    <RcTrans i18nKey='competition:roster.captain' />
  ) : rank === TeamRank.HeadCaptain ? (
    <RcTrans i18nKey='competition:roster.head-captain' />
  ) : rank === TeamRank.HeadCoach ? (
    <RcTrans i18nKey='competition:roster.head-coach' />
  ) : rank === TeamRank.Member ? (
    <RcTrans i18nKey='competition:roster.participant' />
  ) : rank === TeamRank.Substitute ? (
    <RcTrans i18nKey='competition:roster.substitute' />
  ) : (
    <RcTrans i18nKey='competition:roster.pending' />
  )

export const UserCompetitionContacts = ({ userId }: { userId?: number }) => {
  const { contacts } = useCompetitionTeamContactAccounts()

  return (
    <Stack direction='row' spacing={1}>
      {contacts
        ?.filter(it => it.user?.id === userId)
        ?.map((account, index) => (
          <UserNetwork key={index} account={account} condensed disableDelete />
        ))}
    </Stack>
  )
}
