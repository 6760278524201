'use client'

import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import Card from '@mui/material/Card'
import Stack from '@mui/material/Stack'
import Typography from '@mui/material/Typography'
import { isEmpty } from 'lodash-es'
import { InvertedRadiusBox } from '../../molecules/text/InvertedRadiusBox'
import { RcTrans } from '@/components/atoms/RcTrans'
import { SectionHeader } from '@/components/molecules/text/SectionHeader'
import { CardsSection } from '@/components/organisms/card/CardsSection'
import { PageItem } from '@/components/organisms/page/PageItem'
import { PageItemContent } from '@/components/organisms/page/PageItemContent'
import { useFeatures } from '@/components/providers/site/FeatureProvider'
import {
  TutorialItem,
  useCompetitionTutorial
} from '@/entity/competition/useCompetitionTutorial'

export const CompetitionTutorial = () => {
  const { featTutFab } = useFeatures()
  const { tasks, dismiss } = useCompetitionTutorial()

  if (isEmpty(tasks)) {
    return null
  }

  const incomplete = tasks.filter(it => !it.dismissed)
  if (isEmpty(incomplete)) {
    return null
  }

  const content = (
    <CardsSection
      size={230}
      align='flex-start'
      cardItems={tasks}
      render={item => (
        <TutorialCard
          item={item}
          index={incomplete.indexOf(item)}
          handleDismiss={dismiss}
        />
      )}
    />
  )

  if (featTutFab) return content

  return (
    <PageItem contained={false}>
      <PageItemContent pb={0}>
        <SectionHeader
          title={<RcTrans i18nKey='competition:tutorial-header' />}
        />
      </PageItemContent>

      <PageItemContent px={{ xs: 0, sm: 4 }}>{content}</PageItemContent>
    </PageItem>
  )
}

export const TutorialCard = ({
  item,
  handleDismiss,
  index
}: {
  item: TutorialItem
  handleDismiss: (id: string) => void
  index: number
}) => {
  return (
    <Card elevation={3} sx={{ position: 'relative', height: '100%' }}>
      <InvertedRadiusBox pb={0}>
        <Typography variant='h4'>{index >= 0 ? index + 1 : ''}</Typography>
      </InvertedRadiusBox>
      <Stack
        direction='column'
        spacing={3}
        p={3}
        height='100%'
        justifyContent='space-between'
      >
        <Box>
          <Typography variant='h4' pl={9}>
            {item.header}
          </Typography>
          <Typography variant='body2' color='text.secondary' pt={3}>
            {item.children || item.description}
          </Typography>
        </Box>
        <Stack
          direction='row'
          spacing={2}
          alignItems='center'
          sx={{ color: 'text.secondary' }}
        >
          {item.onClick ? (
            <Button size='medium' onClick={ev => item.onClick?.(ev as any)}>
              {item.buttonName ? (
                item.buttonName
              ) : (
                <RcTrans i18nKey='shared.review' />
              )}
            </Button>
          ) : null}
          {item.action ? (
            <Typography variant='subtitle2' color='text.secondary' noWrap>
              {item.action}
            </Typography>
          ) : null}
          {item.dismissable ? (
            <Button
              size='medium'
              color='secondary'
              onClick={() => handleDismiss(item.dismissable!)}
            >
              <RcTrans i18nKey='shared.dismiss' />
            </Button>
          ) : null}
        </Stack>
      </Stack>
    </Card>
  )
}
