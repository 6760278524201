import { RcIconButton } from '@/components/molecules/interactive/RcIconButton'
import { useLeftRightScrolled } from '@/core/hooks/useLeftRightScrolled'
import Box from '@mui/material/Box'
import { ChipProps } from '@mui/material/Chip'
import Grid from '@mui/material/Grid'
import Stack from '@mui/material/Stack'
import React, { ReactNode } from 'react'
import { Maybe } from '../../../../models/types'
import { DiscoverCard } from './DiscoverCard'

export interface CardsSectionProps<T> {
  cardItems?: Maybe<Maybe<T>>[]

  cardLink?: (cardItem: T) => string
  cardMediaSrc?: (cardItem: T) => string | undefined | null
  cardHeaderAvatar?: (cardItem: T) => string | undefined
  cardHeaderTitle?: (cardItem: T) => ReactNode | undefined
  cardHeaderSubheader?: (cardItem: T) => ReactNode | undefined
  cardContent?: (cardItem: T) => React.ReactNode
  primaryChipLabel?: (cardItem: T) => ChipProps[] | undefined

  // override the above and render anything
  render?: (cardItem: T, idx: number) => React.ReactNode

  align?: 'flex-start' | 'center'
  size?: number
  spacing?: number
  interval?: number
}

export const CardsSection = <T,>({
  cardItems,
  primaryChipLabel,
  cardLink,
  cardMediaSrc,
  cardHeaderAvatar,
  cardHeaderTitle,
  cardHeaderSubheader,
  cardContent,
  render,
  align = 'center',
  size = 292,
  spacing = 8,
  interval = 1
}: CardsSectionProps<T & { startDate?: Date; dismissed?: boolean }>) => {
  const { ref, isStart, isEnd } = useLeftRightScrolled(
    cardItems?.filter(it => !it?.dismissed).length
  )

  const CARD_WIDTH = size
  const CARD_SPACING = spacing

  return (
    <>
      <Grid container spacing={4} alignItems='stretch' justifyContent={align}>
        <Grid
          item
          sx={{
            position: 'relative',
            maxWidth: { xs: '100vw', sm: '100%' }
          }}
        >
          <Box sx={{ height: '100%', position: 'relative' }}>
            <Stack
              alignItems='stretch'
              ref={ref}
              direction='row'
              sx={{
                height: '100%',
                paddingX: { xs: 3, sm: 0 },
                overflowX: 'auto',
                overflowY: 'hidden',
                scrollbarWidth: 'none',
                maskImage:
                  isStart && isEnd
                    ? null
                    : isStart
                      ? 'linear-gradient(to right, black 0%, black 6%, black 80%, transparent 100%)'
                      : isEnd
                        ? 'linear-gradient(to right, transparent 0%, black 20%, black 80%, black 100%)'
                        : 'linear-gradient(to right, transparent 0%, black 20%, black 80%, transparent 100%)'
              }}
            >
              {cardItems?.map((it, idx) => {
                if (render)
                  return (
                    <Box
                      key={idx}
                      sx={{
                        height: '100%',
                        overflow: 'hidden',
                        marginRight: it?.dismissed ? 0 : CARD_SPACING / 4,
                        minWidth: it?.dismissed ? 0 : CARD_WIDTH,
                        width: it?.dismissed ? 0 : CARD_WIDTH,
                        transition: 'width 0.3s',
                        '&:last-child': {
                          marginRight: 0
                        }
                      }}
                    >
                      <Box
                        sx={{
                          height: '100%',
                          width: CARD_WIDTH
                        }}
                      >
                        {render(it!, idx)}
                      </Box>
                    </Box>
                  )
                const cardMediaSrcResult = cardMediaSrc?.(it!)
                const cardHeaderAvatarResult = cardHeaderAvatar?.(it!)
                const cardHeaderTitleResult = cardHeaderTitle?.(it!)
                const cardHeaderSubheaderResult = cardHeaderSubheader?.(it!)
                const cardPrimaryChipLabelResult = primaryChipLabel?.(it!)

                return (
                  <DiscoverCard
                    key={idx}
                    sx={{
                      minWidth: it?.dismissed ? 0 : CARD_WIDTH,
                      marginRight: it?.dismissed ? 0 : CARD_SPACING,
                      width: it?.dismissed ? 0 : CARD_WIDTH,
                      transition: 'width 0.3s, margin 0.3'
                    }}
                    navigation={cardLink?.(it!)!}
                    headerImage={cardMediaSrcResult || ''}
                    avatarImage={cardHeaderAvatarResult || ''}
                    title={cardHeaderTitleResult}
                    content={
                      <>
                        {cardHeaderSubheaderResult}
                        {cardContent?.(it!)}
                      </>
                    }
                    primaryChips={cardPrimaryChipLabelResult}
                  />
                )
              })}
            </Stack>
            {isStart && isEnd ? null : (
              <>
                {isStart ? null : (
                  <Box
                    sx={{
                      position: 'absolute',
                      top: 0,
                      left: 0,
                      display: 'flex',
                      flexDirection: 'column',
                      justifyContent: 'center',
                      height: '100%'
                    }}
                  >
                    <RcIconButton
                      size='small'
                      icon={['fal', 'angle-left']}
                      onClick={() => {
                        ref.current?.scrollTo({
                          top: 0,
                          left:
                            ref.current?.scrollLeft -
                            (CARD_WIDTH + CARD_SPACING) * interval,
                          behavior: 'smooth'
                        })
                      }}
                    />
                  </Box>
                )}

                {isEnd ? null : (
                  <Box
                    sx={{
                      position: 'absolute',
                      top: 0,
                      right: 0,
                      display: 'flex',
                      flexDirection: 'column',
                      justifyContent: 'center',
                      height: '100%'
                    }}
                  >
                    <RcIconButton
                      size='small'
                      icon={['fal', 'angle-right']}
                      onClick={() => {
                        ref.current?.scrollTo({
                          top: 0,
                          left:
                            ref.current?.scrollLeft +
                            (CARD_WIDTH + CARD_SPACING) * interval,
                          behavior: 'smooth'
                        })
                      }}
                    />
                  </Box>
                )}
              </>
            )}
          </Box>
        </Grid>
      </Grid>
    </>
  )
}
