import { useRef, useState, useEffect } from 'react'

export function useUpDownScrolled(itemsCount?: number) {
  const ref = useRef<HTMLDivElement>(null)
  const [isTop, setIsTop] = useState(true)
  const [isBottom, setIsBottom] = useState(false)

  useEffect(() => {
    const container = ref.current

    if (!container) return

    const onScroll = () => {
      const { scrollTop, scrollHeight, clientHeight } = container
      setIsTop(scrollTop === 0)
      setIsBottom(scrollTop + clientHeight >= scrollHeight)
    }

    container.addEventListener('scroll', onScroll)
    onScroll()

    return () => container.removeEventListener('scroll', onScroll)
  }, [itemsCount])

  return { ref, isTop, isBottom }
}
