import {
  EntryMemberRequestControllerApi,
  QuerySelfEntryMemberRequestsRequest
} from '@rallycry/api-suite-typescript/dist/apis/EntryMemberRequestControllerApi'
import { AcceptDecline } from '@rallycry/api-suite-typescript/dist/models/AcceptDecline'
import { InviteApply } from '@rallycry/api-suite-typescript/dist/models/InviteApply'
import { useCompetitionMeta } from '../competition/useCompetitionMeta'
import { EntityOptions, useQueryEntity } from '../useEntity'
import { useUserAccount } from '../user/useUserAccount'
import { useCompetitionTeamMembers } from './useCompetitionTeamMembers'
import { useController } from '@/core/hooks/useSWRApi'
import { useParsedParam } from '@/core/hooks/useRouteParams'

export const useCompetitionTeamInvitations = (
  options?: EntityOptions<QuerySelfEntryMemberRequestsRequest>
) => {
  const parsedId = useParsedParam('teamId')
  const { account } = useUserAccount()
  const { read: meta } = useCompetitionMeta()
  const {
    query: { mutate: mutateRoster }
  } = useCompetitionTeamMembers()
  const { ctrl } = useController(EntryMemberRequestControllerApi)

  const teamId = Number(options?.idOrKey) || parsedId
  const entity = useQueryEntity({
    key: 'useCompetitionTeamInvitations',
    expand: 'content{recipient}',
    mutable: true,
    ...options,
    paused: !teamId,
    request: {
      entryId: teamId,
      kind: InviteApply.INVITE,
      status: AcceptDecline.PENDING,
      ...options?.request
    },
    read: req => ctrl().readAllEntryMemberRequests(req)
  })

  const invite = async (recipient: number) => {
    await ctrl().createEntryMemberRequest({
      entryId: teamId,
      EntryMemberRequestCreateCommand: { kind: InviteApply.INVITE, recipient }
    })
    await meta.mutate()
    await entity.query.mutate()
  }

  const accept = async (id: number) => {
    await ctrl().acceptEntryMemberRequest({ id })
    await meta.mutate()
    await entity.query.mutate()
    await mutateRoster()
  }

  const revoke = async (id: number) => {
    await ctrl().removeEntryMemberRequest({ id })
    await meta.mutate()
    await entity.query.mutate()
  }

  const decline = async (id: number) => {
    await ctrl().declineEntryMemberRequest({ id })
    await meta.mutate()
    await entity.query.mutate()
  }

  const invitation = entity.flat.find(
    it => it.recipient?.id === account?.user?.id && it.entry?.id === teamId
  )

  return {
    ...entity,
    invitations: entity.flat,
    invitation,
    invite,
    accept,
    revoke,
    decline
  }
}
